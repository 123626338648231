<app-modal-container [title]="title" [actionsOn]="false">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <button type="button" class="btn-create" (click)="createNewEmail(mosaico)">
        MD Email Builder V1
      </button>
    </div>
    <div class="col d-flex justify-content-center">
      <button type="button" class="btn-create" (click)="createNewEmail(unlayer)">
        MD Email Builder V2
      </button>
    </div>
  </div>
</app-modal-container>
