<app-page-container [title]="tableName">
  <app-messaging-header [tableKey]="tableKey"
                        [permission]="automationMessageCodesPermissions"
                        [folderService]="messageCodeFolderService"
                        [isFolderEmpty]="!data?.length"
                        (folderChanged)="folderSelected($event)"
                        (action)="showCreateMessageCodeModal()">
  </app-messaging-header>

  <app-table [tableKey]="tableKey"
             [permission]="automationMessageCodesPermissions"
             [displayedColumns]="displayedColumns"
             [data]="data"
             [length]="length"
             (tableData)="getMessageCodes($event)"
             (actionEmit)="actionHandler($event)">
  </app-table>
</app-page-container>
