

    <div class="row ml-4 mr-4 mt-4 mb-1">
      <div class="col-md-12">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

          <!--Card Header-->
          <div class="view view-cascade gradient-card-header primary-color narrower
            py-2 mx-4 mb-3 d-flex justify-content-center">
            <h4 class="page-title">Edit Contact Plan</h4>
          </div>
          <!--/Card Header-->

          <!--Card Content-->
          <mdb-card-body cascade="true" class="text-center">
            <section>
              <form [formGroup]="_configuratorFormGroup" (ngSubmit)="this.configuratorFormGroupSubmit()">
                <div class="container-fluid">
                    <input type="hidden" formControlName="id"/>
                    <div class="row mt-3">
                        <div class="col-md-3">
                          <div class="md-form">
                            <input
                              mdbInput
                              type="text"
                              maxlength="225"
                              id="contactPlanTitle"
                              class="form-control"
                              formControlName="title"/>
                            <label for="title">Contact Plan Name</label>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="md-form">
                            <div class="md-form remove-top-margin folder-select">
                              <mdb-select
                                [options]="_folderSelectListOptions"
                                [filterEnabled]="true"
                                [visibleOptions]="10"
                                label="Select Folder"
                                formControlName="folderId"
                                (selected)="folderSelected($event)"
                                >
                              </mdb-select>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 d-flex align-items-start justify-content-start left-buttons-margin-and-padding">
                          <button
                            *ngIf="this._userPermissions.Messaging.AutomationContactPlansContactSteps.Create"
                            type="button"
                            (click)="navigateCreateTriggerStep()"
                            class="btn btn-secondary rounded">
                            <span class="text-truncate">
                              <mdb-icon fas icon="plus" size="lg"></mdb-icon>
                              Create Trigger Step
                            </span>
                          </button>
                          <button
                            *ngIf="this._userPermissions.Messaging.AutomationContactPlansContactSteps.Create"
                            type="button"
                            (click)="navigateCreateMultiListStep()"
                            class="btn btn-secondary rounded">
                            <span class="text-truncate">
                              <mdb-icon fas icon="plus" size="lg"></mdb-icon>
                              Create Multi List Step
                            </span>
                          </button>
                          <button
                            *ngIf="this._userPermissions.Messaging.AutomationContactPlansContactSteps.Create"
                            type="button"
                            (click)="navigateCreateExtractStep()"
                            class="btn btn-secondary rounded">
                            <span class="text-truncate">
                              <mdb-icon fas icon="plus" size="lg"></mdb-icon>
                              Create Extract Step
                            </span>
                          </button>
                      </div>
                      <div class="col-md-6 d-flex align-items-end justify-content-end right-buttons-margin-and-padding"> 
                        <button
                          *ngIf="this._userPermissions.Messaging.AutomationContactPlansContactSteps.Edit"
                          type="button"
                          [routerLink]="['/contact-plans/']"
                          class="btn btn-secondary rounded">
                          <span class="text-truncate">
                            <mdb-icon fas icon="arrow-left"></mdb-icon>
                            Back
                          </span>
                        </button>                             
                        <button
                          *ngIf="this._userPermissions.Messaging.AutomationContactPlansContactSteps.Edit"
                          type="submit"
                          class="btn btn-secondary rounded">
                          <span class="text-truncate">
                            <mdb-icon fas icon="save" size="lg"></mdb-icon>
                            Save
                          </span>
                        </button>
                      </div>                                            
                    </div>
                </div>
              </form>
            </section>
          </mdb-card-body>
          <!--/Card Content-->
        </mdb-card>
        <!--/Card-->
      </div>
    </div>

  <div class="row ml-2 mr-2 mb-2">
    <div class="col-md-12">
      <!--Card-->
      <mdb-card cascade="true" narrower="true" class="mt-5">

        <!--Card Header-->
        <div class="view view-cascade gradient-card-header primary-color narrower
        py-2 mx-4 mb-3 d-flex row justify-content-start">
          <div class="col-md-4 text-left">
            <div *ngIf="this._userPermissions.Messaging.AutomationContactPlansContactSteps.Edit" class="switch mdb-color-switch mt-1 header-button">
              <label class="toggle-all-label" placement="right" mdbTooltip="Toggle Off/On All Steps.">
                <form [formGroup]="globalStatusToggleFormGroup">
                  <span class="white-text unselectable toggle-span-all-off" (click)="setToggleAllSelectedValue(false)">All Off</span>
                  <input formControlName="statusToggle" mdbInput type="checkbox" (change)="confirmToggleAllSteps($event)">
                  <span class="white-text unselectable toggle-span-all-on" (click)="setToggleAllSelectedValue(true)">All On</span>
                </form>
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <h4 class="page-title">Steps</h4>
          </div>
        </div>
        <!--/Card Header-->

        <!--Card Content-->
        <mdb-card-body cascade="true" class="text-center pb-4">
          <section>
            <form [formGroup]="_stepsFormGroup">
              <div class="container-fluid">
                <!-- First Row -->
                <div class="row">
                  <div class="col-md-12">
                    <ngx-datatable
                      class="material striped"
                      [columnMode]="_columnMode.force"
                      [headerHeight]="50"
                      [footerHeight]="50"
                      [rowHeight]="50"
                      [scrollbarV]="false"
                      [scrollbarH]="true"
                      [rows]="_rows"
                      [externalPaging]="true"
                      [count]="_pageCount"
                      [offset]="_pageOffset"
                      [limit]="_pageLimit"
                      (page)="getSteps($event)"
                      (resize)="onResize($event)"
                      >

                        <ngx-datatable-column *ngFor="let col of _columns" [headerClass]="'lists-table-headers'" [name]="col.name" [prop]="col.prop" [width]="col.width">
                            <ng-template let-row="row" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                              <div [ngSwitch]="col.prop">
                                <div *ngSwitchCase="'createDate'">
                                  {{value && isDate(value) ? (value | date:'dd/MM/yyyy HH:mm:ss') : value}}
                                </div>
                                <div *ngSwitchCase="'changeDate'">
                                  {{value && isDate(value) ? (value | date:'dd/MM/yyyy HH:mm:ss') : value}}
                                </div>
                                <div *ngSwitchCase="'status'">
                                  <div class="switch">
                                    <label>
                                      Off
                                      <input type="checkbox" [value]="value" [checked]="value" (click)="confirmActivateStep(row.id)">
                                      <span class="lever"></span> On
                                    </label>
                                  </div>
                                </div>
                                <div *ngSwitchCase="'title'" mdbTooltip="{{_descriptions[rowIndex]}}" placement="left" container="body">
                                  <span>{{ value }}</span>
                                </div>
                                <div *ngSwitchCase="'reportId'">
                                  <a *ngIf="getReportType(value) === _messageTypeEnumEmail" [routerLink]="['/email-reports/details/' + value]">
                                    Email Report
                                  </a>
                                  <a *ngIf="getReportType(value) === _messageTypeEnumSms"  [routerLink]="['/sms-reports/details/' + value]">
                                    SMS Report
                                  </a>
                                </div>
                                <div *ngSwitchDefault>
                                  {{value}}
                                </div>
                            </div>

                            </ng-template>

                        </ngx-datatable-column>

                        <ngx-datatable-column *ngIf="this._userPermissions.Messaging.AutomationContactPlans.Edit || this._userPermissions.Messaging.AutomationContactPlans.Delete"
                          name="Actions" [width]="100" [maxWidth]="100" [cellClass]="'lists-table-right-cells'" [headerClass]="'lists-table-headers'" [frozenRight]="'true'" prop="id">
                          <ng-template let-row="data" let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                            <div class="row button-section rounded mt-1 mr-1 ml-1 text-center">
                              <div class="col-md-4 icon-padding p-0 mt-2 mb-1">
                                <a *ngIf="this._userPermissions.Messaging.AutomationContactPlans.Edit">
                                <mdb-icon fas icon="edit" size="lg" class="options-icon mb-1 mt-1" (click)="confirmEditStep(value)"></mdb-icon></a>
                              </div>
                              <div class="col-md-4 icon-padding p-0 mt-2 mb-1">
                                <a *ngIf="this._userPermissions.Messaging.AutomationContactPlans.Delete">
                                <mdb-icon fas icon="trash" size="lg" class="options-icon mb-1 mt-1" (click)="confirmDeleteStep(value)"></mdb-icon></a>
                              </div>
                              <div class="col-md-4 icon-padding p-0 mt-2 mb-1">
                                <a *ngIf="this._userPermissions.Messaging.AutomationContactPlans.Edit">
                                <mdb-icon fas icon="copy" size="lg" class="options-icon mb-1 mt-1" (click)="confirmCopyStep(value)"></mdb-icon></a>
                              </div>
                            </div>
                          </ng-template>
                        </ngx-datatable-column>
                        <!-- Custom table footer -->
                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="_pageCount" let-pageSize="_pageLimit"
                      let-curPage="_currentPage" let-offset="_pageOffset">
                      <div class="table-custom-footer">
                        <div>
                           Rows: {{ _pageCount }}
                        </div>
                        <select (change)="onLimitChange($event.target.value)" class="table-page-limit-select">
                          <option *ngFor="let option of pageLimitOptions" [ngValue]="option.value"
                            [selected]="option.value == _pageLimit">
                            {{option.value}} per page
                        </option>
                        </select>
                        <datatable-pager class="table-custom-pager" [pagerLeftArrowIcon]="'datatable-icon-left'"
                          [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                          [pagerNextIcon]="'datatable-icon-skip'" [page]="_currentPage" [size]="_pageLimit" [count]="_pageCount"
                          [hidden]="!((_pageCount / _pageLimit) > 1)" (change)="getSteps($event.page)">
                        </datatable-pager>
                      </div>
                    </ng-template>
                  </ngx-datatable-footer>
                    </ngx-datatable>
                  </div>
                </div>
                <!-- /First Row -->
              </div>
            </form>
          </section>
        </mdb-card-body>
        <!--/Card Content-->
      </mdb-card>
      <!--/Card-->
    </div>
  </div>


<!-- Confirm Activate Step Modal -->
<div mdbModal #activateStepModal="mdb-modal" [config]="{ignoreBackdropClick: true}" class="modal fade" id="activateStepModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Confirm Activate Step</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activateStepModal.hide()">
            <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div> <!--modal-header-->
      <div class="modal-body">
        <div class="text-center mb-3">
            <mdb-icon fas icon="question-circle" size="4x" class="animated rotateIn"></mdb-icon>
        </div>  <!--text-center-->

          <div>
            <div class="mb-3">Are you sure you want to {{_stepVerb}} this Step?</div>
            <div class="mb-3">Changes will take effect immediately.</div>
            <div class="modal-footer justify-content-center">
              <button type="submit" mdbBtn color="primary" class="waves-light rounded text-white" mdbWavesEffect (click)="activateStep()">Yes, I am sure</button>
              <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal" (click)="undoActivateStep()" mdbWavesEffect>No, thanks</button>
            </div><!--modal-footer-->
          </div><!--md-form-->

      </div> <!--modal-body-->
    </div>  <!--modal-content-->
  </div> <!--modal-dialog-->
</div> <!--mdbModal-->
<!-- Confirm Activate Step Modal -->


<!-- Confirm Activate Step Modal -->
<div mdbModal #deleteStepModal="mdb-modal" [config]="{ignoreBackdropClick: true}" class="modal fade" id="deleteStepModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Confirm Delete Step</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteStepModal.hide()">
            <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div> <!--modal-header-->
      <div class="modal-body">
        <div class="text-center mb-3">
            <mdb-icon fas icon="question-circle" size="4x" class="animated rotateIn"></mdb-icon>
        </div>  <!--text-center-->
        <div class="mb-3">Are you sure you want to delete this Step?</div>
        <div class="mb-3">You will not be able to revoke this action.</div>
        <div class="modal-footer justify-content-center">
          <button type="submit" mdbBtn color="primary" class="waves-light rounded text-white" (click)="deleteStep()" mdbWavesEffect>Yes, I am sure</button>
          <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal" (click)="deleteStepModal.hide()" mdbWavesEffect>No, thanks</button>
        </div><!--modal-footer-->

      </div> <!--modal-body-->
    </div>  <!--modal-content-->
  </div> <!--modal-dialog-->
</div> <!--mdbModal-->
<!-- Confirm Activate Step Modal -->

<!-- Confirm Toggle All Steps Modal -->
<div mdbModal #toggleAllStepsModal="mdb-modal" [config]="{ignoreBackdropClick: true}" class="modal fade" id="toggleAllStepsModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Confirm Activate Step</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="undoToggleAllSteps()">
            <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div> <!--modal-header-->
      <div class="modal-body">
        <div class="text-center mb-3">
            <mdb-icon fas icon="question-circle" size="4x" class="animated rotateIn"></mdb-icon>
        </div>  <!--text-center-->

          <div>
            <div class="mb-3">Are you sure you want to {{_allStepsVerb}} <span class="font-weight-bold">all</span> Steps?</div>
            <div class="mb-3">Changes will take effect immediately.</div>
            <div [@slide] *ngIf="_allStepsConfirmButton" class="loading-bar-container">
              <mdb-progress value="{{this._timeLeft}}" min="0" max="3" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
            </div>
            <div class="modal-footer justify-content-center">
              <button [disabled]="_allStepsConfirmButton" type="submit" mdbBtn color="primary" class="waves-light rounded text-white" mdbWavesEffect (click)="toggleAllSteps()">Yes, I am sure</button>
              <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal" (click)="undoToggleAllSteps()" mdbWavesEffect>No, thanks</button>
            </div><!--modal-footer-->
          </div><!--md-form-->

      </div> <!--modal-body-->
    </div>  <!--modal-content-->
  </div> <!--modal-dialog-->
</div> <!--mdbModal-->
<!-- Confirm Toggle All Steps Modal -->


<!-- Copy Step Modal -->
<div mdbModal #copyStepModal="mdb-modal" class="modal fade" id="copyStepModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-notify modal-warning" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="heading lead">Confirm Copy Step</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="copyStepModal.hide()">
            <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div> <!--modal-header-->
      <div class="modal-body">
        <div class="text-center mb-3">
            <mdb-icon fas icon="question-circle" size="4x" class="animated rotateIn"></mdb-icon>
        </div><!--text-center-->
        <form [formGroup]="_copyStepFormGroup" (ngSubmit)="this.copyStepSubmit()">
          <div class="row">
            <div class="col">Would you like to make a copy this Step?</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="md-form">
                <input
                  mdbInput
                  type="text"
                  maxlength="225"
                  id="title"
                  class="form-control"
                  formControlName="title">
                <label for="title">Step Name</label>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button [disabled]="!_copyStepFormGroup.valid" type="submit" mdbBtn color="primary" class="waves-light rounded text-white" mdbWavesEffect>Yes</button>
            <button type="button" mdbBtn color="secondary" class="rounded" outline="true" data-dismiss="modal" (click)="copyStepModal.hide()" mdbWavesEffect>No</button>
          </div><!--modal-footer-->
        </form>
      </div> <!--modal-body-->
    </div>  <!--modal-content-->
  </div> <!--modal-dialog-->
</div> <!--mdbModal-->
<!-- Copy Step Modal -->
