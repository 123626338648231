import { Component, Injector, OnInit } from '@angular/core';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MessageCodeFolderService } from 'src/app/_services/messaging/automation/message-codes/message-code-folder.service';
import { MessageCodeService } from 'src/app/_services/messaging/automation/message-codes/message-code.service';
import { MessageCodeGridRecordDtoAdapter } from 'src/app/_models/messaging/automation/message-codes/message-code-grid-record-dto-adapter';
import { PermissionsPageTypes } from '../../../../_models/system/permissions/permissions-page-types-model';
import {
  CreateAutomationModalComponent
} from '../../../shared/modals/create-automation/create-automation-modal.component';
import { TableActionType, TableName } from '../../../shared/constants/table-constants';
import { TrialRunModalComponent } from '../../../shared/modals/trial-run/trial-run-modal.component';
import { MessagingAbstract } from '../../../../_models/messaging/messaging-abstract';

@Component({
  selector: 'app-message-codes',
  templateUrl: './message-codes.component.html'
})

export class MessageCodesComponent extends MessagingAbstract implements OnInit {
  displayedColumns = [
    { name: 'title', displayName: 'Title' },
    { name: 'folderName', displayName: 'Folder' },
    { name: 'createDate', displayName: 'Creation Date' },
    { name: 'createdBy', displayName: 'Created By' },
    { name: 'changeDate', displayName: 'Last Modified Date' },
    { name: 'changedBy', displayName: 'Last Modified By' },
    { name: 'actions', displayName: '' }
  ];

  editPath = 'contact-criteria/edit/';
  automationMessageCodesPermissions: PermissionsPageTypes;
  trialRunOpen = false;

  constructor(
    public messageCodeFolderService: MessageCodeFolderService,
    private messageCodeGridRecordDtoAdapter: MessageCodeGridRecordDtoAdapter,
    private messageCodeService: MessageCodeService,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'contactCriteria';
    this.init();
  }

  ngOnInit() {
    this.automationMessageCodesPermissions = this.permissions.AutomationMessageCodes;
  }

  getMessageCodes(data: any): void {
    if (this.trialRunOpen) {
      return;
    }

    this.loadingSpinnerService.loading();
    const pagerDtoRequest = new PagerDtoRequest({
      page: data.currentPage,
      pageSize: data.pageLimit,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection
    });

    this.messageCodeService.getAll(pagerDtoRequest, this.selectedFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.data = executionResultDto.data.MessageCodes.map((messageCode) => this.messageCodeGridRecordDtoAdapter.adapt(messageCode));
        this.length = executionResultDto.data.Total;

        if (!this.length) {
          this.loadingSpinnerService.stopLoading();
          return;
        }
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
      this.loadingSpinnerService.stopLoading();
    }, error => {
      this.loadingSpinnerService.stopLoading();
    });
  }

  showCreateMessageCodeModal() {
    const folders = this.messagingHeaderService.folders.filter(x => x.value > 0);
    const dialog = this.matDialog.open(CreateAutomationModalComponent, {
      width: '500px',
      data: {
        data: this.data,
        title: TableName[this.tableKey],
        tableKey: this.tableKey,
        folders,
        selectedFolder: this.selectedFolderId
      }
    });

    dialog.afterClosed().subscribe(({ isNeedToUpdate, folderId }) => {
      if (isNeedToUpdate) {
        this.messagingHeaderService.setFolder(folderId);
        this.tableService.requestTableData();
      }
    });
  }

  openTrialRunModal(row): void {
    this.trialRunOpen = true;
    const dialog = this.matDialog.open(TrialRunModalComponent, {
      maxWidth: '100vw',
      maxHeight: '45vw',
      width: '100%',
      data: { row }
    });

    dialog.afterClosed().subscribe(() => {
      this.trialRunOpen = false;
    });
  }

  actionHandler({ selectedRows, action, extraOptions }): void {
    switch (TableActionType[action]) {
      case TableActionType.trial:
        this.openTrialRunModal(selectedRows);
        break;
      case TableActionType.edit:
        this.editRow(selectedRows, this.editPath);
        break;
      case TableActionType.delete:
        this.deleteRestoreRow(selectedRows, extraOptions);
        break;
    }
  }
}
