import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  Injector,
  OnDestroy
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MDBDatePickerComponent,
  ClockPickerComponent,
  UploadFile
} from 'ng-uikit-pro-standard';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ListFolderService } from 'src/app/_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { ListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model';
import { ContactService } from 'src/app/_services/messaging/lists-and-contacts/contacts/contact.service';
import { ContactDtoRequest } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-dto-request';
import {
  SearchContactDtoRequest
} from 'src/app/_models/messaging/lists-and-contacts/contacts/search-contact-dto-request';
import {
  ContactFieldDtoAdapter
} from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-field-dto-adapter';
import { ContactFieldDto } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-field-dto';
import { ListControlTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/contacts/list-control-type-enum';
import { DateService } from 'src/app/_services/system/date.service';
import { Subscription } from 'rxjs';
import { UnsubscribeService } from 'src/app/_services/system/unsubscribe.service';
import { DatePipe, Location } from '@angular/common';
import { ListTypeEnum } from 'src/app/_models/messaging/lists-and-contacts/lists/list-type-enum';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { MessagingAbstract } from '../../../../_models/messaging/messaging-abstract';
import { PermissionsPageTypes } from '../../../../_models/system/permissions/permissions-page-types-model';
import { TableActionType, TableName } from '../../../shared/constants/table-constants';
import {
  CreateEditContactModalComponent
} from '../../../shared/modals/create-contact/create-edit-contact-modal.component';
import { UploadContactsModalComponent } from '../../../shared/modals/upload-contacts/upload-contacts-modal.component';
import { ActivatedRoute, Params } from '@angular/router';
import {
  SubscriptionCommentModalComponent
} from '../../../shared/modals/subscription-comment/subscription-comment-modal.component';
import { ContactDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/contacts/contact-dto-adapter';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
})

export class ContactsComponent extends MessagingAbstract implements OnInit, OnDestroy {
  userPermissionsContacts: PermissionsPageTypes;
  contactFieldDtos: ContactFieldDto[] = [];
  actionList = ['uploadNewList', 'uploadList', 'uploadHistory'];
  selectedListId: number;
  lists = [];
  disableCreate: boolean;
  fileUploaded = false;
  file: UploadFile;
  isExistingList: boolean;
  newName: string;
  exportRows: {[prop: string]: any}[] = [];
  getContactFieldsSubscription: Subscription;
  getContactsSubscription: Subscription;
  postSubscription: Subscription;
  filterContactRules = '';
  params: Params;
  comment: string;

  @ViewChildren(MDBDatePickerComponent) datepickers: QueryList<MDBDatePickerComponent>;
  @ViewChildren(ClockPickerComponent) timepickers: QueryList<ClockPickerComponent>;

  constructor(
    public activatedRoute: ActivatedRoute,
    public listFolderService: ListFolderService,
    public listsService: ListsService,
    private contactService: ContactService,
    private contactFieldDtoAdapter: ContactFieldDtoAdapter,
    private datePipe: DatePipe,
    private helperService: HelperService,
    private location: Location,
    private contactDtoAdapter: ContactDtoAdapter,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'contacts';
    this.init();
  }

  ngOnInit() {
    if (this.paginationDataService.getFilterRules())
      this.filterContactRules = this.paginationDataService.getFilterRules();
    this.selectedListId = 0;
    this.userPermissionsContacts = this.permissions.ListsAndContactLists;
    this.params = this.activatedRoute.snapshot.params;
    this.location.replaceState('/contacts');
  }

  disableCreateButton(listId: number, lists): void {
    if (this.selectedListId === 0) {
      this.disableCreate = true;
    } else {
      const selectedList: ListModel = lists.find(l => l.ListID === this.selectedListId);
      this.disableCreate = selectedList?.ListType === ListTypeEnum['Car Alerts'];
    }
  }

  getMessageHistory(row): void {
    const email = row.Email ? row.Email : ' ';
    const mobile = row.Mobile ? row.Mobile : ' ';
    this.router.navigate([`/messages/message-history/${ this.selectedFolderId }/${ this.selectedListId }/${ email }/${ mobile }`]);
  }

  getRows(executionResultDto: ExecutionResultDto): {[prop: string]: any}[] {
    let rows: {[prop: string]: any}[] = [];
    const dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID === 10);
    const dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID === 8);

    rows = executionResultDto.data.Records.map(record => {
      const row: {[prop: string]: any} = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (dateTimeColumns.some(val => val.Name === key)) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
          } else if (dateColumns.some(val => val.Name === key)) {
            const date = new Date(record[key]);
            row[key] = this.datePipe.transform(date, 'dd/MM/yyyy');
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        }
      });

      return row;
    });

    return rows;
  }

  getRowsForExport(executionResultDto: ExecutionResultDto, columns: string[]): {[prop: string]: any} [] {
    let rows: {[prop: string]: any}[] = [];
    const dateTimeColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID === 10);
    const dateColumns = executionResultDto.data.Columns.filter(element => element.FieldTypeID === 8);
    const columnsToExport = this.displayedColumns.filter((column) => columns.find((col) => col === column.displayName ));

    rows = executionResultDto.data.Records.map((record) => {
      const row: {[prop: string]: any} = {};
      Object.keys(record).map(key => {
        const propColumn = columnsToExport.find(col => col.name === key);
        if (propColumn) {
          let friendlyName = propColumn.displayName;
          if (columnsToExport.some(col => col.displayName === friendlyName)) {
            if (friendlyName.indexOf(',') !== -1) {
              friendlyName = friendlyName.replace(/,/g, '');
            }
            if (record[key] !== null && record[key] !== undefined) {
              if (dateTimeColumns.some(val => val.Name === key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
              } else if (dateColumns.some(val => val.Name === key)) {
                const date = new Date(record[key]);
                row[friendlyName] = this.datePipe.transform(date, 'dd/MM/yyyy');
              } else if (record[key].toString().toLowerCase() === 'true') {
                row[friendlyName] = 'Yes';
              } else if (record[key].toString().toLowerCase() === 'false') {
                row[friendlyName] = 'No';
              } else if (this.isValidContactNumber(record[key])) {
                row[friendlyName] = `=\"${ record[key] }\"`;
              } else {
                row[friendlyName] = record[key];
              }
            } else {
              row[friendlyName] = record[key];
            }
          }
        }
      });

      return row;
    });

    return rows;
  }

  isValidContactNumber(value) {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
  }

  getContactFields(row?) {
    this.loadingSpinnerService.loading();

    this.contactService.getContactFields(this.selectedListId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.contactFieldDtos = executionResultDto.data.map(data => this.contactFieldDtoAdapter.adapt(data));
        this.openCreateEditContact(row);
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  openCreateEditContact(row?): void {
    const folders = this.messagingHeaderService.folders.filter(x => x.value > 0);
    const lists = this.messagingHeaderService.lists;
    const config = {
      width: '500px',
      data: {
        selectedFolder: this.selectedFolderId,
        selectedList: this.selectedListId,
        data: this.data,
        title: TableName[this.tableKey],
        tableKey: this.tableKey,
        fields: this.contactFieldDtos,
        folders,
        lists,
        row
      }
    };

    const dialog = this.matDialog.open(CreateEditContactModalComponent, config);
    this.loadingSpinnerService.stopLoading();

    dialog.afterClosed().subscribe(({ needCreate, selectedContact, fields, contactFormGroup }) => {
      if (needCreate) {
        this.post(selectedContact, fields, contactFormGroup);
      }
    });
  }

  getContacts(data?): void {
    this.loadingSpinnerService.loading();
    this.filterContactRules = data.filters;
    this.paginationDataService.setFilterRules(this.filterContactRules);
    const pagerDtoRequest = new PagerDtoRequest({
      page: data.currentPage,
      pageSize: data.pageLimit,
      sortColumn: data.sortColumn,
      sortDirection: data.sortDirection
    });

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      listId: this.selectedListId,
      contactFilter: data.filters,
      pagerDtoRequest
    });

    this.getContactsSubscription = this.contactService.getContacts(getContactsPagerDtoRequest)
      .subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.displayedColumns = executionResultDto.data.Columns.map((column, i) => {
            if (executionResultDto.data.Columns.length !== i + 1) {
              return {
                name: column.Name,
                displayName: column.DisplayName.replace(/([a-z])([A-Z])/g, '$1 $2')
              };
            } else {
              return {
                name: 'actions',
                displayName: ''
              };
            }
          });

          this.data = this.getRows(executionResultDto);
          this.length = executionResultDto.data.Total;
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
        this.loadingSpinnerService.stopLoading();
      });
  }

  folderSelected(id: number): void {
    this.selectedFolderId = id;
    this.paginationDataService.SetFolderIdToLocalStorage(id);
    this.loadingSpinnerService.stopLoading();
  }

  listSelected({ listId, lists }): void {

    if (this.selectedListId != undefined && this.selectedListId != 0 && this.selectedListId != listId) {
      this.paginationDataService.SetPaginationDataToLocalStorage
        (this.currentPage, this.pageLimit, this.sortColumn, this.sortDirection, this.tableKey);
    }

    this.selectedListId = listId;
    this.lists = lists;
    this.paginationDataService.SetListIdToLocalStorage(listId);
    this.disableCreateButton(listId, lists);

    if (this.activatedRoute.snapshot.params.hasOwnProperty('newList')) {
      this.uploadContact(this.activatedRoute.snapshot.params.newList);
    }

    this.tableService.requestTableData();
  }

  export(columns: string[]): void {
    const pagerDtoRequest = new PagerDtoRequest({
      page: 1,
      pageSize: 1000000,
      sortColumn: 'UpdatedDate',
      sortDirection: 'DESC'
    });

    const getContactsPagerDtoRequest = new SearchContactDtoRequest({
      listId: this.selectedListId,
      contactFilter: this.filterContactRules,
      pagerDtoRequest
    });

    this.contactService.getContacts(getContactsPagerDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.exportRows = this.getRowsForExport(executionResultDto, columns);
        const listName = this.lists.find((list) => list.value === this.selectedListId).label;
        const today = new Date();
        const fileName = `${ listName }_${ today.getDate() }${ today.getMonth() + 1 }${ today.getFullYear() }`;
        this.helperService.exportToCSV(fileName, this.exportRows);
      } else {
        this.notificationService.showError(executionResultDto.message);
        return;
      }
    });
  }

  post(selectedContact: number, fields: ContactFieldDto[], contactFormGroup: FormGroup): void {
    const creatingNewContact = selectedContact === 0;

    if (!creatingNewContact && this.showSubscriptionCommentModal(selectedContact, fields, contactFormGroup)) {
      return;
    }
     
    fields.map(x => {
      if (x.controlTypeId === ListControlTypeEnum.DatePicker || x.controlTypeId === ListControlTypeEnum.DateTimePicker) {
        const dateString = contactFormGroup.get(x.name).value;
        if (dateString && dateString.length > 0) {
          x.value = x.controlTypeId === ListControlTypeEnum.DatePicker ?
          DateService.convertUkDateToANSI(dateString) : DateService.convertUkDateTimeToANSI(dateString);
        }
      } else if (x.controlTypeId === ListControlTypeEnum.Checkbox) {
        var selectedOptions: string[] = [];
        x.options.map(opt => {
          const checkboxValue = contactFormGroup.get([`${x.name}-${opt.optionValue}`]).value.toString();
            if (opt.optionValue === checkboxValue || checkboxValue === 'true') {
              selectedOptions.push(opt.optionValue);
            }
        });
        const csvCheckedCheckboxes = selectedOptions.join();
        x.value = csvCheckedCheckboxes;
      } else {
        x.value = contactFormGroup.get(x.name).value;
      }
    });

    const addContactDtoRequest = new ContactDtoRequest({
      contactId: selectedContact,
      listId: this.selectedListId,
      contactFields: fields,
      subscriptionComment: this.comment
    });

    this.postSubscription = this.contactService.post(addContactDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.notificationService.showSuccess(executionResultDto.message);
        this.comment = '';
        this.tableService.requestTableData();
      } else {
        this.comment = '';
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  uploadContact(toNewList: boolean): void {
    const dialog = this.matDialog.open(UploadContactsModalComponent, {
      width: '500px', data: {
        selectedList: this.selectedListId,
        newUpload: true,
        toNewList
      }
    });

    dialog.afterClosed().subscribe(({ fileUploaded, file, isNewList, newListName }) => {
      if (fileUploaded) {
        this.fileUploaded = true;
        this.file = file;
        this.isExistingList = !isNewList;
        this.newName = newListName;
      }
    });
  }

  contactsSaved(): void {
    this.fileUploaded = false;
    this.tableService.requestTableData();
  }

  showSubscriptionCommentModal(selectedContact: number, fields: ContactFieldDto[], contactFormGroup: FormGroup): boolean {
    if ((contactFormGroup.get('Consent_Email').value && contactFormGroup.get('Consent_Email').value.toLowerCase() === 'true' &&
        this.contactFieldDtos.find(x => x.name === 'Consent_Email').value.toLowerCase() !== 'true') ||
        (contactFormGroup.get('Consent_SMS').value && contactFormGroup.get('Consent_SMS')?.value.toLowerCase() === 'true' &&
        this.contactFieldDtos.find(x => x.name === 'Consent_SMS').value.toLowerCase() !== 'true')) {
      if (!this.comment) {
        const dialog = this.matDialog.open(SubscriptionCommentModalComponent);

        dialog.afterClosed().subscribe(( { isNeedToUpdate, comment }) => {
          if (isNeedToUpdate) {
            this.comment = comment;
            this.post(selectedContact, fields, contactFormGroup);
          }
        });

        return true;
      }
    }

    return false;
  }

  actionHandler({ selectedRows, action, extraOptions }): void {
    switch (TableActionType[action]) {
      case TableActionType.getMessageHistory:
        this.getMessageHistory(selectedRows);
        break;
      case TableActionType.edit:
        this.getContactFields(selectedRows);
        break;
      case TableActionType.delete:
        extraOptions.listId = this.selectedListId;
        this.deleteRestoreRow(selectedRows, extraOptions);
        break;
      case TableActionType.export:
        this.export(selectedRows);
        break;
      case TableActionType.uploadNewList:
        this.uploadContact(true);
        break;
      case TableActionType.uploadList:
        this.uploadContact(false);
        break;
      case TableActionType.uploadHistory:
        this.router.navigate(['/contacts-upload-history']);
        break;
    }
  }

  ngOnDestroy(): void {
    UnsubscribeService.unsubscribe(
      this.getContactFieldsSubscription,
      this.getContactsSubscription,
      this.postSubscription,
    );
  }
}
