import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';
import { EventInfoModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/event-info-model';
import { EventInfoModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/event-info-model.adapter';
import { EventTypeEnum } from 'src/app/_models/messaging/automation/triggers/trigger-enums/event-type.enum';
import { WebFormViewModel } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-view-model';
import { WebFormViewModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/forms/web-form-view-model.adapter';
import { trigger, transition, style, animate } from '@angular/animations';
import { CreateEditTriggerComponent } from '../create-edit-trigger.component';
import { LightListFieldDtoAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-field-dto.adapter';
import { LightListFieldDto } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-field-dto';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { QueryBuilderComponent } from 'src/app/views/shared/query-builder/query-builder.component';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { ActivatedRoute } from '@angular/router';
import { MessageCodeStepService } from 'src/app/_services/messaging/automation/message-codes/message-code-step.service';
import { MessageCodeStepDtoAdapter } from 'src/app/_models/messaging/automation/message-codes/message-code-step-dto-adapter';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { MessageCodeStepDto } from 'src/app/_models/messaging/automation/message-codes/message-code-step-dto';
import { NotificationService } from 'src/app/_services/notification.service';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { MessageLinkDtoAdapter } from 'src/app/_models/messaging/messages/email-messages/message-link-dto.adapter';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { MessageLinkDto } from 'src/app/_models/messaging/messages/email-messages/mesage-link-dto';

@Component({
  selector: 'app-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
            style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
            style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class TriggerComponent implements OnInit {
  @Input() triggerFormGroup: UntypedFormGroup;
  @Input() actionRelatesFormGroup: UntypedFormGroup;
  @Output() validationCheck = new EventEmitter<string>();
  @ViewChild('queryBuilderModal') _queryBuilderModal: ModalDirective;
  @ViewChild('queryBuilder') private queryBuilder: QueryBuilderComponent;
  @ViewChild('contactFilterCriteria') contactFilterCriteria;

  _triggerEvents: Array<EventInfoModel>;
  _triggerEventsDropdown: Array<any> = [];
  _contactFilters: Array<any> = [];
  _webFormDropdown: Array<any> = [];
  _timeBasedWWCDropdown: Array<any> = [];
  _showTimeBasedFields: Boolean = false;
  _showTimeBasedCCFields: Boolean = false;
  _showTimeBasedOneOffSendFields: Boolean = false;
  timeBasedFormGroup: any;
  timeBasedWCCFormGroup: any;
  timeBasedOneOffSendFormGroup: any;
  _listFields: Array<LightListFieldDto>;
  _listFieldsDropdown: Array<any> = [];
  _messageLinksDropdown: Array<any> = [];
  _listID: number;
  _initQueryBuilder: boolean = false;
  _contactFilterRules: string;
  _messagesLinksDropdownPopulated = false;
  _triggerIsLink = false;
  _triggerIsTargetLink = false;
  _pageTitle = 'Triggers';
  _isTimeBasedValueSelected = false;

  _frequencyDropdown: Array<any> = [
    { label: 'Once', value: 1, }
  ];

  _daysWeeksMonths: Array<any> = [
    { label: 'Day(s)', value: 3, },
    { label: 'Week(s)', value: 4, },
    { label: 'Month(s)', value: 5, },
  ];
  _beforeAfter: Array<any> = [
    { label: 'Before Today', value: 1, },
    { label: 'After Today', value: 2, },
  ];

  constructor(
    public createEditTriggerComponent: CreateEditTriggerComponent,
    private triggersService: TriggersService,
    private eventInfoModelAdapter: EventInfoModelAdapter,
    private webFormViewModelAdapter: WebFormViewModelAdapter,
    private lightListFieldDtoAdapter: LightListFieldDtoAdapter,
    private route: ActivatedRoute,
    private messageCodeStepService: MessageCodeStepService,
    private messageCodeStepDtoAdapter: MessageCodeStepDtoAdapter,
    private messageLinkDtoAdapter: MessageLinkDtoAdapter,
    private notificationService: NotificationService,
    private emailMessageService: EmailMessagesService
  ) { }

  ngOnInit() {
    this.onChanges();

    this.route.data.subscribe(data => {
      this._triggerEvents = this.eventInfoModelAdapter.adaptArray(data.triggerEvents.data);
    });
    this.timeBasedFormGroup = this.triggerFormGroup.controls.timeBasedFormGroup;
    this.timeBasedWCCFormGroup = this.triggerFormGroup.controls.timeBasedWCCFormGroup;
    this.timeBasedOneOffSendFormGroup = this.triggerFormGroup.controls.timeBasedOneOffSendFormGroup;
    this.populateTriggerEventsDropdown();
  }

  onChanges() {
    this.actionRelatesFormGroup.get('triggerActionType').valueChanges.subscribe(val => {
      if (val) {
        this.populateTriggerEventsDropdown();
        this.clearTimeBasedForm();
        this.allValidatorsFalse();
      }
    });

    this.triggerFormGroup.get('trigger').valueChanges.subscribe(val => {

      let value = this._triggerEventsDropdown.find(e => e.value == val && e.label == 'Time Based');
      if (value == undefined) {
        this._isTimeBasedValueSelected = false;
      }
      else {
        this._isTimeBasedValueSelected = true;
      }

      this.triggerSelected(val);
    });

    this.actionRelatesFormGroup.get('messageType').valueChanges.subscribe(val => {
      if (val) {
        this.populateTriggerEventsDropdown();
        this.clearTimeBasedForm();
        this.allValidatorsFalse();
      }
    });

    this.actionRelatesFormGroup.get('list').valueChanges.subscribe(val => {
      this.timeBasedFormGroup.get('field').setValue(null);
      this._listID = this.actionRelatesFormGroup.get('list').value;
      this._initQueryBuilder = false;
      setTimeout(() => {
        this._initQueryBuilder = true;
      });
      this.getListFields();
      this.clearTimeBasedForm();
    });
  }

  getTriggerEvents() {
    this.triggersService.getTriggerEvents().subscribe(result => {
      this._triggerEvents = this.eventInfoModelAdapter.adaptArray(result.data);
      this.populateTriggerEventsDropdown();
    }, error => {
      console.log(error);
    });
  }

  // We need to populate the trigger dropdown based on what the user has selected in the first section.
  // listEvents = A trigger against the following list.
  // !listEvents = The following message being sent.
  // messageType = Email.
  // !messageType = SMS.
  populateTriggerEventsDropdown() {
    this._webFormDropdown = [];
    this._timeBasedWWCDropdown = [];
    this._showTimeBasedFields = false;
    this._showTimeBasedCCFields = false;
    this._showTimeBasedOneOffSendFields = false;
    this.triggerFormGroup.get('trigger').setValue(null);
    this._triggerEventsDropdown = [];
    var triggerType = this.actionRelatesFormGroup.get('triggerActionType').value;
    var messageType = this.actionRelatesFormGroup.get('messageType').value;
    this._triggerEvents.forEach((event: EventInfoModel) => {
      if (triggerType === 'list') {
        if (event.SubjectType === EventTypeEnum.List || event.SubjectType === EventTypeEnum.ListField) {
          this._triggerEventsDropdown = [... this._triggerEventsDropdown, {
            value: event.EventID,
            label: event.DisplayName
          }];
        }
      } else {
        if (messageType && event.SubjectType === EventTypeEnum.Message) {
          if (messageType === MessageTypeEnum.email && event.SubjectEventType !== 5) {
            this._triggerEventsDropdown = [... this._triggerEventsDropdown, {
              value: event.EventID,
              label: event.DisplayName
            }];
          } else if (messageType === MessageTypeEnum.sms && event.SubjectEventType === 5) {
            this._triggerEventsDropdown = [... this._triggerEventsDropdown, {
              value: event.EventID,
              label: event.DisplayName
            }];
          }
        }
      }
    });
  }

  populateTimeBasedDropdownWithContactCriteria(listId: number) {

    this._timeBasedWWCDropdown = [];

    if (!listId) { return; }

    if (this.triggerFormGroup.get('trigger').value !== EventTypeEnum.Message) { return; }

    this.messageCodeStepService.getByAllListId(listId).subscribe(executionResultDto => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const messageCodeStepDtos = executionResultDto.data.map((data: MessageCodeStepDto) => this.messageCodeStepDtoAdapter.adapt(data));

        if (messageCodeStepDtos.length === 0) {
          this.notificationService.showWarning('No Contact Criteria Codes found for this List. Please create some.');
          return;
        }

        this._timeBasedWWCDropdown = messageCodeStepDtos.map((option: MessageCodeStepDto) =>
                                    ({label: option.criteriaCode, value: option.messageCodeStepId}));
      }
    });
  }

  triggerSelected(eventID: number) {
    this._webFormDropdown = [];
    this._timeBasedWWCDropdown = [];
    this._showTimeBasedFields = false;
    this._showTimeBasedCCFields = false;
    this._showTimeBasedOneOffSendFields = false;
    this._triggerIsLink = false;
    this._triggerIsTargetLink = false;
    this.createEditTriggerComponent._showTimingStep = true;
    this.triggerFormGroup.get('form').setValue(null);
    this.triggerFormGroup.get('messageLinkId').setValue(null);
    this.triggerFormGroup.get('messageLinkId').setValidators(null);
    this.triggerFormGroup.get('targetUrl').setValue('');

    this.allValidatorsFalse();
    this.createEditTriggerComponent.showHideTimingStep(true);
    var webForms: Array<WebFormViewModel>;
    switch (eventID) {
      case 12:
        this.triggersService.getWebFormsByTypeID(this._listID, 1).subscribe (result => {
          webForms = this.webFormViewModelAdapter.adaptArray(result.data);
          this.populateFormDropdown(webForms);
          this.triggerFormGroup.get('form').setValidators([Validators.required]);
        });
        break;
      case 14:
        this.triggersService.getWebFormsByTypeID(this._listID, 3).subscribe (result => {
          webForms = this.webFormViewModelAdapter.adaptArray(result.data);
          this.populateFormDropdown(webForms);
          this.triggerFormGroup.get('form').setValidators([Validators.required]);
        });
        break;
      case 16:
        this.triggersService.getWebFormsByTypeID(this._listID, 2).subscribe (result => {
          webForms = this.webFormViewModelAdapter.adaptArray(result.data);
          this.populateFormDropdown(webForms);
          this.triggerFormGroup.get('form').setValidators([Validators.required]);
        });
        break;
      case 1:
        this.clearTimeBasedForm();
        this.showTimeBasedFields();
        this.createEditTriggerComponent.showHideTimingStep(false);
        break;
      case 2:
        this.clearTimeBasedForm();
        this.showTimeBasedCCFields();
        this.createEditTriggerComponent.showHideTimingStep(false);
        this.populateTimeBasedDropdownWithContactCriteria(this._listID);
        this.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').setValue(null);
        this.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').setValidators([Validators.required]);
        this.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').updateValueAndValidity();
        break;
      case 5:
        this._triggerIsLink = true;
        if (this.actionRelatesFormGroup.get('message').value) {
          this.populateMessageLinksDropdown(this.actionRelatesFormGroup.get('message').value);
          this._messagesLinksDropdownPopulated = true;
        }
        this.triggerFormGroup.get('messageLinkId').setValidators([Validators.required]);
        this.triggerFormGroup.get('messageLinkId').updateValueAndValidity();
        break;
      case 18:
        this.clearTimeBasedForm();
        this.showTimeBasedOneOffSendFields();
        this.createEditTriggerComponent.showHideTimingStep(false);
        this.triggerFormGroup.get('timeBasedOneOffSendFormGroup').get('oneOffSendDateTime').setValue(null);
        this.triggerFormGroup.get('timeBasedOneOffSendFormGroup').get('oneOffSendDateTime').setValidators([Validators.required]);
        this.triggerFormGroup.get('timeBasedOneOffSendFormGroup').get('oneOffSendDateTime').updateValueAndValidity();
        break;
      case 20:
        this._triggerIsTargetLink = true;
        break;
      default:
        this.triggerFormGroup.get('form').setValue(null);
        this.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').setValue(null);
        this.clearTimeBasedForm();
        break;
    }
    this.triggerFormGroup.updateValueAndValidity();
    this.timeBasedFormGroup.updateValueAndValidity();
    this.timeBasedWCCFormGroup.updateValueAndValidity();
    this.timeBasedOneOffSendFormGroup.updateValueAndValidity();
  }

  populateFormDropdown(webForms: Array<WebFormViewModel>) {
    this.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').setValue(null);
    webForms.forEach((form: WebFormViewModel) => {
      this._webFormDropdown = [... this._webFormDropdown, {
        value: form.WebFormID,
        label: form.WebFormName
      }];
    });
  }

  showTimeBasedFields() {
    this._showTimeBasedFields = true;
    this.getListFields();
    // tslint:disable-next-line: forin
    for (const key in this.timeBasedFormGroup.controls) {
      this.timeBasedFormGroup.get(key).setValidators([Validators.required]);
      this.timeBasedFormGroup.get(key).updateValueAndValidity();
    }
  }

  showTimeBasedCCFields() {
    this._showTimeBasedCCFields = true;
    this.getListFields();
    // tslint:disable-next-line: forin
    for (const key in this.timeBasedWCCFormGroup.controls) {
      this.timeBasedWCCFormGroup.get(key).setValidators([Validators.required]);
      this.timeBasedWCCFormGroup.get(key).updateValueAndValidity();
    }
  }

  showTimeBasedOneOffSendFields() {
    this._showTimeBasedOneOffSendFields = true;
    this.getListFields();
    // tslint:disable-next-line: forin
    for (const key in this.timeBasedOneOffSendFormGroup.controls) {
      this.timeBasedOneOffSendFormGroup.get(key).setValidators([Validators.required]);
      this.timeBasedOneOffSendFormGroup.get(key).updateValueAndValidity();
    }
  }

  getListFields() {
    if (this._listID) {
      this.triggersService.getLightListFields(this._listID, true).subscribe( result => {
        this._listFields = this.lightListFieldDtoAdapter.adaptArray(result.data);
        this.populateFieldDropdown();
      });
    }
  }

  populateFieldDropdown() {
    this._listFieldsDropdown = [];
    this._listFields.forEach((field: LightListFieldDto) => {
      if (field.SqlType === 'datetime') {
        this._listFieldsDropdown = [ ... this._listFieldsDropdown, {
          value: field.ColumnName,
          label: field.DisplayName,
        }];
      }
    });
  }

  allValidatorsFalse() {
    this.triggerFormGroup.get('form').clearValidators();
    this.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').clearValidators();
    this.triggerFormGroup.get('form').updateValueAndValidity();
    this.triggerFormGroup.get('timeBasedWCCFormGroup').get('contactCriteria').updateValueAndValidity();
    this.triggerFormGroup.get('messageLinkId').clearValidators();
    this.triggerFormGroup.get('messageLinkId').updateValueAndValidity();
    this.triggerFormGroup.get('timeBasedOneOffSendFormGroup').get('oneOffSendDateTime').clearValidators();
    this.triggerFormGroup.get('timeBasedOneOffSendFormGroup').get('oneOffSendDateTime').updateValueAndValidity();
    // tslint:disable-next-line: forin
    for (const key in this.timeBasedFormGroup.controls) {
      this.timeBasedFormGroup.get(key).clearValidators();
      this.timeBasedFormGroup.get(key).updateValueAndValidity();
    }
    // tslint:disable-next-line: forin
    for (const key in this.timeBasedWCCFormGroup.controls) {
      this.timeBasedWCCFormGroup.get(key).clearValidators();
      this.timeBasedWCCFormGroup.get(key).updateValueAndValidity();
    }
    for (const key in this.timeBasedOneOffSendFormGroup.controls) {
      this.timeBasedOneOffSendFormGroup.get(key).clearValidators();
      this.timeBasedOneOffSendFormGroup.get(key).updateValueAndValidity();
    }
  }

  clearLinks() {
    this._messageLinksDropdown = [];
  }

  clearTimeBasedForm() {
    this.timeBasedFormGroup.get('field').setValue(null);
    this.timeBasedFormGroup.get('numberOf').setValue(null);
    this.timeBasedFormGroup.get('daysWeeksMonths').setValue(null);
    this.timeBasedFormGroup.get('beforeAfter').setValue(null);
    this.timeBasedFormGroup.get('time').setValue(null);
  }

  contactsFilterSave(event) {
    const text = event[0] === null && event[1] === null ? '' : event[1].sql;
    this.triggerFormGroup.get('contactFilterCriteria').setValue(text);
    this._queryBuilderModal.hide();
  }

  viewContactsFilterSaved() {
    return this.triggerFormGroup.get('contactFilterCriteria').value.replace(/(?<=\w)''(?=\w)/gm, `\'`);
  }

  populateMessageLinksDropdown(messageId: number) {

    this.emailMessageService.getLinks(messageId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        const messageLinkDtos = executionResultDto.data.map((messageLinkDto: MessageLinkDto) => this.messageLinkDtoAdapter.adapt(messageLinkDto));
        this._messageLinksDropdown = [];
        this._messageLinksDropdown = messageLinkDtos.map((messageLinkDto: MessageLinkDto) => ({
                                                                                                  value: messageLinkDto.linkId,
                                                                                                  label: messageLinkDto.linkTitle
                                                                                                }));
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
     });
  }
}
