import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageSaveModel } from 'src/app/_models/messaging/messages/email-messages/message-save-model';
import { ExecutionResultDto, ExecutionResultAdapter } from 'src/app/_models/execution-result-model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ContactsMessagePreviewModel } from 'src/app/_models/messaging/messages/email-messages/contacts-message-preview-model';
import { MessageSendDtoRequest } from 'src/app/_models/messaging/messages/message-send-dto-request';

@Injectable({
  providedIn: 'root'
})
export class EmailMessagesService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'EmailMessages';

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
    ) { }

  saveMessage(message: MessageSaveModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/SaveMessage`, message)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getMessage(messageID: number): Observable<ExecutionResultDto> {
    const query = `?MessageID=${messageID}`;
    
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetMessage${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getLinks(messageId: number): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetLinks/${messageId}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getListFolders(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetListFolders`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getLists(folderID: number): Observable<ExecutionResultDto> {
    const query = `${folderID}`;

    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetLists/${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getPreviewMessage(messageID: number): Observable<ExecutionResultDto> {
    const query = `?messageID=${messageID}`;
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/Preview${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getPublicPreviewMessage(clientIdToken: string, messageIdToken: string): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}Public/Messages/CheckEmailOnline/${clientIdToken}/${messageIdToken}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  generatePublicPreviewTokens(messageId: number): Observable<ExecutionResultDto> {    
      return this.http
      .get(`${this._baseUrl}Public/Messages/GeneratePublicPreviewTokens/${messageId}`)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getContactsPreviewMessage(messagePreviewModel: ContactsMessagePreviewModel): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/ContactsPreview`, messagePreviewModel)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  send(messageSendDtoRequest: MessageSendDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/Send`, messageSendDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }
  
}
