export const environment = {
  production: true,
  apiURL: "https://api.uat.voicebox.marketing.delivery/",
  formBuilderURL: 'https://formeditor.uat.voicebox.marketingdelivery.net/formeditor.html',
  formPublishURL: 'https://forms.uat.voicebox.marketingdelivery.net/?',
  emailBuilderURL: 'https://emaileditor.uat.voicebox.marketingdelivery.net/editor.html',

  sessionIdlePeriod: 3600,
  sessionTimeoutPeriod: 30,
  maxClientBuckets: 2,
  vehicleValidationAPIUrl: 'https://vehiclevalidator.marketing.delivery/regnbr/json',
  vehicleValidationAPIKey: '',
};
