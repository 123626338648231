import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MailEdit } from '../../constants/app-constants';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-email-builder',
  templateUrl: './select-email-builder-modal.component.html',
  styleUrls: ['./select-email-builder-modal.component.scss']
})
export class SelectEmailBuilderModalComponent {
  title = 'Select Email Editor';
  mosaico = MailEdit.mosaico;
  unlayer = MailEdit.unlayer;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SelectEmailBuilderModalComponent>
  ) { }

  createNewEmail(emailEditor): void {
    this.router.navigate([`messages/email-builder-${emailEditor}`]);
    this.dialogRef.close();
  }
}
