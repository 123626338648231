<app-page-container [title]="tableName">
  <app-messaging-header [tableKey]="tableKey"
                        [permission]="userPermissionsContacts"
                        [folderService]="listFolderService"
                        [listsService]="listsService"
                        [isFolderEmpty]="!data?.length"
                        [showPlaceholder]="true"
                        [disableActionButton]="disableCreate"
                        [snapshot]="params"
                        (folderChanged)="folderSelected($event)"
                        (listChanged)="listSelected($event)"
                        (action)="getContactFields()">
  </app-messaging-header>

  <app-table [tableKey]="tableKey"
             [permission]="userPermissionsContacts"
             [displayedColumns]="displayedColumns"
             [data]="data"
             [selectedFolderId]="selectedFolderId"
             [selectedListId]="selectedListId"
             [length]="length"
             [actionList]="actionList"
             [isSearchOn]="true"
             [ngClass]="{ 'd-none': fileUploaded }"
             (tableData)="getContacts($event)"
             (actionEmit)="actionHandler($event)"
             [filterMessageRules]="filterContactRules">
  </app-table>

  <app-contacts-upload *ngIf="fileUploaded"
                       [selectedFolderId]="selectedFolderId"
                       [selectedListId]="selectedListId"
                       [isExistingList]="isExistingList"
                       [file]="file"
                       [newName]="newName"
                       (contactsSaved)="contactsSaved()">
  </app-contacts-upload>
</app-page-container>

