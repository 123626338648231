import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { FolderDtoRequest } from 'src/app/_models/messaging/messages/folder-dto-request';
import { DeleteFolderDtoRequest } from 'src/app/_models/messaging/messages/delete-folder-dto-request';

@Injectable({
  providedIn: 'root'
})

export class MessageCodeFolderService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'MessageCodeFolder';

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) { }

  delete(id: number): Observable<ExecutionResultDto> {
    return this.http
    .delete(`${this._baseUrl}${this._controllerName}/Delete/${id}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  createUpdate(folderDtoRequest: FolderDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}`, folderDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getAll(): Observable<ExecutionResultDto> {
    return this.http
    .get(`${this._baseUrl}${this._controllerName}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  updateDelete(deleteFolderDtoRequest: DeleteFolderDtoRequest): Observable<ExecutionResultDto> {
    return this.http
    .post(`${this._baseUrl}${this._controllerName}/UpdateDelete`, deleteFolderDtoRequest)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

}

