<app-page-container [title]="tableName">
  <app-messaging-header [tableKey]="tableKey"
                        [permission]="userPermissionsEmail"
                        [folderService]="messageFolderService"
                        [isFolderEmpty]="!data?.length"
                        (folderChanged)="folderSelected($event)"
                        (action)="selectEmailBuilder()">
  </app-messaging-header>

  <app-table [tableKey]="tableKey"
             [permission]="userPermissionsEmail"
             [displayedColumns]="displayedColumns"
             [data]="data"
             [length]="length"
             [selectedFolderId]="selectedFolderId"
             [isSwitchOn]="true"
             [isSearchOn]="true"
             [actionList]="actionList"
             (tableData)="getMessages($event)"
             (actionEmit)="actionHandler($event)"
             [filterMessageRules]="filterMessagesRules">
  </app-table>
</app-page-container>

