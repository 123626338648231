<app-page-container [title]="tableName">
  <app-messaging-header [tableKey]="tableKey"
                        [permission]="userPermissionsLists"
                        [folderService]="listFolderService"
                        [isFolderEmpty]="!data?.length"
                        (folderChanged)="folderSelected($event)"
                        (action)="showCreateListModal()">
  </app-messaging-header>

  <app-table [tableKey]="tableKey"
             [permission]="userPermissionsLists"
             [displayedColumns]="displayedColumns"
             [data]="data"
             [selectedFolderId]="selectedFolderId"
             [length]="length"
             [actionList]="actionList"
             (tableData)="getLists($event)"
             (actionEmit)="actionHandler($event)">
  </app-table>
</app-page-container>
