
export class MessagePreviewModel {
  public MessageID: number;
  public MessageName: string;
  public Subject: string;
  public HtmlContent: string;
  public FromName: string;
  public FromAddress: string;
  public ReplyToAddress: string;

  constructor(init: any) {
    this.MessageID = init.MessageID;
    this.MessageName = init.MessageName;
    this.Subject = init.Subject;
    this.HtmlContent = init.HtmlContent;
    this.FromName = init.FromName;
    this.FromAddress = init.FromAddress;
    this.ReplyToAddress = init.ReplyToAddress;
  }
}
