<!-- Save Filter Modal -->
<div mdbModal #saveFilterModal="mdbModal" class="confirm-modal modal fade" tabindex="-1" role="dialog" aria-labelledby="saveFilterModalLabel" aria-hidden="true" [config]="{backdrop: false,  keyboard: false, ignoreBackdropClick: true}">
    <div class="modal-dialog" role="document">
      <div class="modal-content rounded-lg">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modelOpenEvent.emit(false); saveFilterModal.hide()">
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title w-100" id="saveFilterModalLabel">Save Filter</h4>
        </div>
        <form [formGroup]="_newFilterForm" (ngSubmit)="saveFilterSubmit()">
          <div class="modal-body">
            <div class="form-group pl-16 pr-16">
              <label class="input-label ml-0" for="filterName">Filter Name</label>
              <input type="text" maxlength="225" class="form-control" formControlName="filterName" id="filterName">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-cancel-modal" aria-label="Close"
            (click)="modelOpenEvent.emit(false); saveFilterModal.hide()"> Cancel </button>
            <button type="submit" [disabled]="!_newFilterForm.valid" class="btn-save-modal">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
