import { Component, Injector } from '@angular/core';
import { MessageReportsService } from 'src/app/_services/messaging/messages/message-reports.service';
import {
  EmailMessageReportsRetrievingModel
} from 'src/app/_models/messaging/messages/message-reports/email-reports/email-message-reports-retrieving-model';
import {
  MessageReportGridViewModelAdapter
} from 'src/app/_models/messaging/messages/message-reports/email-reports/message-report-grid-view-model.adapter';
import {
  MessageReportGridViewModel
} from 'src/app/_models/messaging/messages/message-reports/email-reports/message-report-grid-view-model';
import { DateService } from 'src/app/_services/system/date.service';
import { HelperService } from 'src/app/_services/system/helpers/helper.service';
import { MessagingAbstract } from '../../../../_models/messaging/messaging-abstract';
import { MessageFolderService } from '../../../../_services/messaging/messages/message-folder.service';
import { ExecutionResult } from '../../../../_models/execution-result-enum';
import { ExecutionResultDto } from '../../../../_models/execution-result-model';
import { TableActionType } from '../../../shared/constants/table-constants';

@Component({
  selector: 'app-message-reports',
  templateUrl: './message-reports.component.html',
})
export class MessageReportsComponent extends MessagingAbstract {
  displayedColumns = [
    { name: 'listId', displayName: 'List ID' },
    { name: 'messageName', displayName: 'Message Name' },
    { name: 'messageSourceName', displayName: 'Message Source Name' },
    { name: 'sentBy', displayName: 'Sent By' },
    { name: 'folderName', displayName: 'Folder Name' },
    { name: 'lastSentDate', displayName: 'Last Sent Date' },
    { name: 'totalSent', displayName: 'Total Sent' },
    { name: 'totalOpens', displayName: 'Total Opens' },
    { name: 'openRate', displayName: 'Open Rate (%)' },
    { name: 'totalBounces', displayName: 'Total Bounces' },
    { name: 'bounceRate', displayName: 'Bounce Rate (%)' },
    { name: 'totalClicks', displayName: 'Total Clicks' },
    { name: 'clickThroughRate', displayName: 'Click Through Rate (%)' },
    { name: 'actions', displayName: '' }
  ];

  emailReportModel: EmailMessageReportsRetrievingModel;

  constructor(
    private messageReportGridViewModelAdapter: MessageReportGridViewModelAdapter,
    private dateService: DateService,
    private helperService: HelperService,
    public messageFolderService: MessageFolderService,
    public messageReportsService: MessageReportsService,
    injector: Injector
  ) {
    super(injector);
    this.tableKey = 'emailReports';
    this.init();
  }

  getReports(data): void {
    this.loadingSpinnerService.loading();
    this.emailReportModel = {
      PagerModel: {
        Page: data.currentPage,
        PageSize: data.pageLimit,
        SortColumn: data.sortColumn,
        SortDirection: data.sortDirection
      },
      FolderID: this.selectedFolderId
    };

    this.messageReportsService.getEmailReports(this.emailReportModel).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this.data = this.messageReportGridViewModelAdapter.adaptArray(executionResultDto.data.Item1);
        this.length = executionResultDto.data.Item2;
        this.formatDate();
        this.roundRates();
        this.loadingSpinnerService.stopLoading();
      } else {
        this.notificationService.showError(executionResultDto.message);
        this.loadingSpinnerService.stopLoading();
      }
    });
  }

  formatDate(): void {
    this.data.forEach((report: MessageReportGridViewModel) => {
      report.lastSentDate = this.dateService.formatStringDate(report.lastSentDate);
    });
  }

  roundRates(): void {
    this.data.forEach((report: MessageReportGridViewModel) => {
      report.openRate = (+report.openRate * 100).toFixed(0);
      report.bounceRate = (+report.bounceRate * 100).toFixed(0);
      report.clickThroughRate = (+report.clickThroughRate * 100).toFixed(0);
    });
  }

  actionHandler({ selectedRows, action }): void {
    switch (TableActionType[action]) {
      case TableActionType.openDetails:
        this.router.navigate([ 'email-reports/details/', selectedRows.reportId]);
        break;
      case TableActionType.export:
        this.messageReportsService.export(this.data, selectedRows.map(row => {
          return {
            displayName: row,
            name: this.displayedColumns.find(x => x.displayName === row)?.name
          }
        }));
        break;
    }
  }
}
