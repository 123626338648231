import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ValidatorFn, } from '@angular/forms';
import { FolderDto } from 'src/app/_models/messaging/messages/folder-dto';
import { FolderDtoAdapter } from 'src/app/_models/messaging/messages/folder-dto.adapter';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { TriggerModel } from 'src/app/_models/messaging/automation/triggers/trigger-model';
import { TriggerModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-model.adapter';
import { TriggerTargetModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-target-model.adapter';
import { TriggerSchedulingModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/trigger-scheduling-model.adapter';
import { TriggerExecutionDataModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-execution-data-model.adapter';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { EventTypeEnum } from 'src/app/_models/messaging/automation/triggers/trigger-enums/event-type.enum';
import { SchedulingTypeEnum } from 'src/app/_models/messaging/automation/triggers/trigger-enums/scheduling-type.enum';
import { TriggersService } from 'src/app/_services/messaging/automation/triggers/triggers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Location } from '@angular/common';
import { ListFolderService } from 'src/app/_services/messaging/lists-and-contacts/list-folders/list-folder.service';
import { ListFolderModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model.adapter';
import { LightListModel } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model';
import { ListFolderModel } from 'src/app/_models/messaging/lists-and-contacts/lists/list-folder-model';
import { LightweightMessageModel } from 'src/app/_models/messaging/messages/lightweight-message-model';
import { LightweightMessageModelAdapter } from 'src/app/_models/messaging/messages/lightweight-message-model.adapter';
import { EmailContactModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/email-contact-model';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { animate, style, transition, trigger } from '@angular/animations';
import { DataTableColumn } from 'src/app/_models/messaging/datatable-column';
import { TriggerExecutionDataModel } from 'src/app/_models/messaging/automation/triggers/trigger-execution-data-model';
import { ListsService } from 'src/app/_services/messaging/lists-and-contacts/lists/lists.service';
import { LightListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/light-list-model.adapter';
import { EmailMessagesService } from 'src/app/_services/messaging/messages/email-messages/email-messages.service';
import { MessageService } from 'src/app/_services/messaging/messages/message.service';
import { ListModelAdapter } from 'src/app/_models/messaging/lists-and-contacts/lists/list-model.adapter';
import * as moment from 'moment';
import { SmsMessageService } from 'src/app/_services/messaging/messages/sms-messages/sms-message.service';
import { ChangeModel } from 'src/app/_models/messaging/automation/triggers/trigger-params/update-contact-action-info-models/change-model';
import { ChangeModelAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/update-contact-action-info-models/change-model.adapter';
import { QueryBuilderComponent } from 'src/app/views/shared/query-builder/query-builder.component';
import { UpdateContactActionInfo } from 'src/app/_models/messaging/automation/triggers/trigger-params/update-contact-action-info';
import { SendMessageActionInfoAdapter } from 'src/app/_models/messaging/automation/triggers/trigger-params/send-message-action-info.adapter';
import { UpdateContactsComponent } from './actions-to-perform-multi-list/update-contacts/update-contacts.component';
import { SecondListUpdateContactsComponent } from './actions-to-perform-multi-list/second-list-update-contacts/second-list-update-contacts.component';

@Component({
  selector: 'app-create-edit-multi-list-trigger',
  templateUrl: './create-edit-multi-list-trigger.component.html',
  styleUrls: ['./create-edit-multi-list-trigger.component.scss'],
  animations: [
    trigger(
      'slide',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.5s ease-out',
            style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('.5s ease-in',
            style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ] 
})
export class CreateEditMultiListTriggerComponent implements OnInit {

  @ViewChild('messagePreviewModal', { static: true }) _messagePreviewModal: ModalDirective;
  @ViewChild('contactTable', { static: true }) contactTable: DatatableComponent; 
  @ViewChild('queryBuilderModal', { static: true }) queryBuilderModal: ModalDirective;
  @ViewChild('queryBuilder', { static: true }) queryBuilder: QueryBuilderComponent;
  @ViewChild('updateContactsComponent', { static: true }) updateContactsComponent: UpdateContactsComponent;
  @ViewChild('updateContactValueModal', { static: true }) updateContactValueModal: ModalDirective;
  @ViewChild('secondListUpdateContactsComponent', { static: true }) secondListUpdateContactsComponent: SecondListUpdateContactsComponent;
  @ViewChild('secondListUpdateContactValueModal', { static: true }) secondListUpdateContactValueModal: ModalDirective;
    
  triggerDetailsForm: UntypedFormGroup = new UntypedFormGroup ({
    title: new UntypedFormControl(null, Validators.required),
    triggerID: new UntypedFormControl(0),
  });

  stepForm: UntypedFormGroup = new UntypedFormGroup ({
    listForm: new UntypedFormGroup({
      triggerActionType: new UntypedFormControl('list'),
      listFolder: new UntypedFormControl(null, Validators.required),
      list: new UntypedFormControl(null, Validators.required),
      contactFilterCriteria: new UntypedFormControl(),
      updateValuesFormGroup: new UntypedFormGroup({
        updateContactData: new UntypedFormControl(),
      }),
      
      secondListFolder: new UntypedFormControl(null, Validators.required),
      secondList: new UntypedFormControl(null, Validators.required),
      secondListContactFilterCriteria: new UntypedFormControl(),
      secondListUpdateValuesFormGroup: new UntypedFormGroup({
        updateContactData: new UntypedFormControl(),
      }),
    }),    
    fieldsForm: new UntypedFormGroup({
      fields: new UntypedFormArray([], this.minSelectedCheckboxes(1))
    }),
    secondListfieldsForm: new UntypedFormGroup({
      secondListFields: new UntypedFormArray([], this.minSelectedCheckboxes(1))
    }),
    messageForm: new UntypedFormGroup({    
      messageType: new UntypedFormControl(MessageTypeEnum.email),
      messageFolder: new UntypedFormControl(null, Validators.required),
      message: new UntypedFormControl(null, Validators.required),
      time: new UntypedFormControl(null, Validators.required)      
    })    
  });  


  get fieldsArray() {
    return this.stepForm.get('fieldsForm').get('fields') as UntypedFormArray;
  }

  get secondListFieldsArray() {
    return this.stepForm.get('secondListfieldsForm').get('secondListFields') as UntypedFormArray;
  }

  _allColumns: DataTableColumn[] = [];
  _columns: DataTableColumn[] = [];
  _columnMode = ColumnMode;
  _dateCulture = 'en-GB';  
  _eventTypeEnum: EventTypeEnum; 
  _fields = []; 
  _secondListFields = []; 
  _lightLists: Array<LightListModel> = [];
  _secondListLightLists: Array<LightListModel> = [];
  _lightListsDropdown: Array<any> = []; 
  _secondListLightListsDropdown: Array<any> = []; 
  _listFolders: Array<ListFolderModel> = [];  
  _secondListListFolders: Array<ListFolderModel> = []; 
  _listFolderDropdown: Array<any> = []; 
  _secondListListFolderDropdown: Array<any> = []; 
  _lightListsDropdownForContacts: Array<any> = [];
  _messages: Array<LightweightMessageModel> = [];
  _messageFolders: Array<FolderDto> = [];
  _messageFoldersDropdown: Array<any> = [];
  _messagePreviewHtml = ''; 
  _notifyMessageDropdown: Array<any> = [];    
  _rows: { [prop: string]: any }[] = [];
  _selectedContacts: Array<any> = [];
  _selectedContactsEmail: Array<EmailContactModel> = [];
  _selectedListIdForNotifiedContacts = 0;
  _selectContactsModalOpened = false; 
  _selectedMessageFolderId = 0;
  _selectedMessageId = 0;
  _showNotifyForm: boolean = false;  
  selectionType = SelectionType.multiClick;
  _triggerToEdit: TriggerModel; 
  _triggerEdit = false;
  _triggerParameters: TriggerExecutionDataModel;
  _titleText: string = 'Create Multi List Step';   
  _visibleColumns = [];
  _workflowId = 0; 
  _pageTitle = 'Create Edit Multi List';
  updateValuesFormGroup: any;
  secondListUpdateValuesFormGroup: any;
  _readableContactValues: Array<any> = [];
  _secondListReadableContactValues: Array<any> = [];
  _smsMessageContent = '';
  _smsMessageContentDate = '';
  _messageTypeNum = 1;
  _currentSendMessageType: MessageTypeEnum = MessageTypeEnum.email;
  _updateContactValues: Array<ChangeModel> = [];
  _secondListUpdateContactValues: Array<ChangeModel> = [];

  _selectedListId: number;
  _secondListSelectedListId: number;
  _selectedContactFilterCriteria = '';
  _pageTitleForQueryBuilder = '';
  _queryBuilderParentSource = '';
  _isSecondList: boolean = false;
  _isSecondListUpdateValues = false;
  _selectedFolderId = -1;
  _secondListSelectedFolderId = -1;

  _listName: string = '';
  _secondListName: string = '';


  constructor(
    private messageFolderDtoAdapter: FolderDtoAdapter,    
    private listFolderService: ListFolderService,    
    private listsService: ListsService,   
    private listFolderModelAdapter: ListFolderModelAdapter,
    private lightListModelAdapter: LightListModelAdapter,    
    private triggerModelAdapter: TriggerModelAdapter,
    private triggerTargetModelAdapter: TriggerTargetModelAdapter,    
    private triggerSchedulingModelAdapter: TriggerSchedulingModelAdapter,
    private triggerExecutionDataModelAdapter: TriggerExecutionDataModelAdapter,
    private sendMessageActionInfoAdapter: SendMessageActionInfoAdapter,
    private triggersService: TriggersService,   
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private location: Location,
    private router: Router,
    private lightweightMessageModelAdapter: LightweightMessageModelAdapter,       
    private emailMessageService: EmailMessagesService,
    private messageService: MessageService,
    private listModelAdapter: ListModelAdapter,
    public smsMessageService: SmsMessageService,
    private changeModelAdapter: ChangeModelAdapter,
  ) {  }   


  ngOnInit() {

    this.initTriggerModel();    
    this.getRouteInfo(); 
    this.populateForm(); 
    this.onChanges();    
  }  

  onChanges() {

    this.stepForm.controls.messageForm.get('messageType').valueChanges.subscribe(val => {
      this._currentSendMessageType = val;
      if (this.stepForm.controls.messageForm.get('messageFolder').value != null) {
        this.GetLightMessages(this.stepForm.controls.messageForm.get('messageFolder').value, 'send');
      }
      this.stepForm.controls.messageForm.get('message').setValue(null);
    });

    this.stepForm.get('messageForm').get('message').valueChanges.subscribe((val: any) => {
      if (val) {      
        this._selectedMessageId = val;
      }
    }); 
    
    this.stepForm.get('listForm').get('list').valueChanges.subscribe(val => {
      if (val) {
        this.updateContactsComponent.getContactFields(val);
      }
    });

    this.stepForm.get('listForm').get('secondList').valueChanges.subscribe(val => {
      if (val) {
        this.secondListUpdateContactsComponent.getContactFields(val);
      }
    });

    this.updateContactsComponent.fields.valueChanges.subscribe(() => {
      this.showSelectedContactValues();
    });

    this.secondListUpdateContactsComponent.secondListFields.valueChanges.subscribe(() => {
      this.secondLitShowSelectedContactValues();
    });

    this.updateContactValueModal.closed.subscribe(() => {
      this.populateUpdateContactValues();
      this.showSelectedContactValues(); 
    });

    this.secondListUpdateContactValueModal.closed.subscribe(() => {
      this.secondListPopulateUpdateContactValues();
      this.secondLitShowSelectedContactValues();  
    });
  }

  GetLightMessages(folderID: number, from: string) {
    var lightMessages: Array<LightweightMessageModel>;
    if (folderID) {
      this.messageService.getLightMessages(folderID, this._currentSendMessageType).subscribe(result => {
        lightMessages = this.lightweightMessageModelAdapter.adaptArray(result.data);
        this.populateLightSendMessagesDropdown(lightMessages);
      }, error => {
        console.log(error);
      });
    }
  }

  populateLightSendMessagesDropdown(lightMessages: Array<LightweightMessageModel>) {
    this._notifyMessageDropdown = [];
    lightMessages.forEach((message: LightweightMessageModel) => {
      this._notifyMessageDropdown = [... this._notifyMessageDropdown, {
        value: message.MessageID,
        label: message.MessageName
      }];
    });
  }

  addRemoveMessageValidation() {    

    const validator = this._showNotifyForm ? [Validators.required] : null; 

    const messageForm = this.stepForm.get('messageForm');
    
    messageForm.get('message').setValidators(validator);
    messageForm.get('contacts').setValidators(validator);         
    
    messageForm.get('message').updateValueAndValidity();
    messageForm.get('contacts').updateValueAndValidity();   
  }
  
  contactsFilterSave(event) {
    const text = event[0] === null && event[1] === null ? '' : event[1].sql;    

    if (this._isSecondList) {
      this.stepForm.get('listForm').get('secondListContactFilterCriteria').setValue(text);
    }
    else {
      this.stepForm.get('listForm').get('contactFilterCriteria').setValue(text);
    }
    
    this.queryBuilderModal.hide();
  }

  clearAll() {
    this._selectedContacts = [];
    this._selectedContactsEmail = [];  
    this.contactTable.selected = [];    
    this.stepForm.get('messageForm').get('contacts').setValue([]);       
  }

  getContactsFilterSaved() {
    return this.stepForm.get('listForm').get('contactFilterCriteria').value.replace(/(?<=\w)''(?=\w)/gm, `\'`);
  }

  secondListGetContactsFilterSaved() {
    return this.stepForm.get('listForm').get('secondListContactFilterCriteria').value.replace(/(?<=\w)''(?=\w)/gm, `\'`);
  }
  
  getList(listId: number) : Promise<ExecutionResultDto> { 
    return this.listsService.getListById(listId).toPromise();      
  }

  getRows(executionResultDto: ExecutionResultDto) {
    let rows: { [prop: string]: any }[] = [];
    rows = executionResultDto.data.Records.map(record => {
      const row: { [prop: string]: any } = {};
      Object.keys(record).map(key => {
        if (record[key] !== null && record[key] !== undefined) {
          if (this.isDate(record[key])) {
            const date = new Date(record[key]);
            row[key] = `${date.toLocaleDateString(this._dateCulture, { day: 'numeric', month: 'numeric', year: 'numeric' })} ${date.toLocaleTimeString()}`;
          } else if (record[key].toString().toLowerCase() === 'true') {
            row[key] = 'Yes';
          } else if (record[key].toString().toLowerCase() === 'false') {
            row[key] = 'No';
          } else {
            row[key] = record[key];
          }
        }
      });
      return row;
    });

    return rows;
  }  

  getRouteInfo() {
    this.route.params.subscribe(params => {      
      this._workflowId = params.contactPlanId;
      if (params.triggerID) {                
        this._triggerEdit = true;
        this._titleText = 'Edit Multi List Step';
      }
    });    

    this.route.data.subscribe(data => { 
      this._messageFolders = this.messageFolderDtoAdapter.adaptArray(data.messageFolders.data);

      if (data.trigger) {        
        if (data.trigger.data) {
          this._triggerToEdit = this.triggerModelAdapter.adapt(data.trigger.data);
        }
        else {
          this._triggerToEdit = this.triggerModelAdapter.adapt(data.trigger.triggerData.data);
        }
        this._triggerToEdit.Parameters = this.triggerExecutionDataModelAdapter.adapt(this._triggerToEdit.Parameters);

      }       
    });    
  } 

  getSelectedFieldIds() : number[] { 
    const selectedIds = this.stepForm.get('fieldsForm').get('fields').value
      .map((checked, i) => checked ? this._fields[i].id : null)
      .filter(x => x!== null);

    return selectedIds;        
  }

  isDate(value) {
    return moment(value.toString().substring(0, 10), 'YYYY-MM-DD', true).isValid();
  }

  async populateListsForEdit(listId: number, secondListId: number) {

    var firstListResultDto = await this.getList(listId);    
    
    if (firstListResultDto.executionResult === ExecutionResult.success) {      
      const list = this.listModelAdapter.adapt(firstListResultDto.data[0]);      
      this.populateListFolders(list.FolderID);
      this.populateLists(list.FolderID, listId);
    } 
    
    var secondListResultDto = await this.getList(secondListId);

    if (secondListResultDto.executionResult === ExecutionResult.success) {      
      const secondList = this.listModelAdapter.adapt(secondListResultDto.data[0]);      
      this.populateSecondListFolders(secondList.FolderID);
      this.populateSecondLists(secondList.FolderID, secondListId);
    } 
  }

  initTriggerModel() {
    this._triggerToEdit = this.triggerModelAdapter.createEmpty();
    this._triggerToEdit.ActionType = 'MultiList';
    var parameters = this.triggerExecutionDataModelAdapter.createEmptyForTwoLists();
    parameters.Scheduling = this.triggerSchedulingModelAdapter.createEmpty();
    parameters.Scheduling.SchedulingType = SchedulingTypeEnum.OneOffSend
    parameters.NotifyContacts = null;    
    parameters.Extract = null;

    var target = this.triggerTargetModelAdapter.createEmpty();   
    target.SubjectType = EventTypeEnum.ListField;
    target.SubjectEventType = EventTypeEnum.ListField;
    target.EventName = 'Time Based One Off Send';
    target.MessageType = MessageTypeEnum.email;
    parameters.TriggerTarget = target;    
        
    this._triggerToEdit.Parameters = parameters;
  }

  onCancelClicked() {
    this.location.back();
  } 
  
  onClosed() {
    this._selectContactsModalOpened = false
  }  
  
  onListFolderSelected(event) {        
    this.populateLists(+event.value);
    this._selectedFolderId = +event.value;
  }  

  onSecondListFolderSelected(event) {        
    this.populateSecondLists(+event.value);
    this._secondListSelectedFolderId = +event.value;
  }  

  onListSelected (event){    
    this.populateListFields(+event.value);      
    this._selectedListId = +event.value;
    this._listName = event.label;

    this.resetQueryBuilderData(false);

    this.showWarningIfSameListSelected();
  }

  onSecondListSelected(event){    
    this.populateSecondListFields(+event.value);    
    this._secondListSelectedListId = +event.value;
    this._secondListName = event.label;

    this.resetQueryBuilderData(true);

    this.showWarningIfSameListSelected();
  }

  onMessageFolderSelected(event) {   
    this._selectedMessageFolderId = event.value;
    this.populateMessages(+event.value);
  }
  
  onOpened() {
    this._selectContactsModalOpened = true
  }  
  
  resetQueryBuilderData(isSecondList: boolean) {
  
    var listId = 0;

    if (isSecondList) {
      this.stepForm.get('listForm').get('secondListContactFilterCriteria').setValue('');
        this._selectedContactFilterCriteria = '';
        this._queryBuilderParentSource = 'contacts';
        this._pageTitleForQueryBuilder = 'CreateEditMultiListTrigger1'; 
        listId = this._secondListSelectedListId;
    } else {
      this.stepForm.get('listForm').get('contactFilterCriteria').setValue('');
      this._selectedContactFilterCriteria = '';
      this._queryBuilderParentSource = 'contacts';
      this._pageTitleForQueryBuilder = 'CreateEditMultiListTrigger';      
      listId = this._selectedListId;  
    }
    
    this.queryBuilder.populateQueryFilterList(this._pageTitleForQueryBuilder);
    this.initQueryBuilder(listId, this._queryBuilderParentSource, this._selectedContactFilterCriteria);   
  }
  
  showWarningIfSameListSelected() {
    if (this._selectedFolderId == this._secondListSelectedFolderId && this._selectedListId == this._secondListSelectedListId) {
      this.notificationService.showWarning("Please select a different list from the folder. The selected list is already in use on this trigger.");
    }
  }

  populateContactFilterCriteria(sqlFilter: string, sqlFilter1: string) {
    this.stepForm.get('listForm').get('contactFilterCriteria').setValue(sqlFilter);    
    this.stepForm.get('listForm').get('secondListContactFilterCriteria').setValue(sqlFilter1); 
  }

  populateForm () {     

    this.triggerDetailsForm.get('title').setValue(this._triggerToEdit.Title);
    this.triggerDetailsForm.get('triggerID').setValue(this._triggerToEdit.TriggerId);     
        

    if (this._triggerEdit) {        
      this.populateListsForEdit(this._triggerToEdit.Parameters.TriggerTarget.ListId, this._triggerToEdit.Parameters.SecondListInfo.ListId);
      this.populateContactFilterCriteria(this._triggerToEdit.Parameters.ContactsFilter.SqlFilter, this._triggerToEdit.Parameters.SecondListInfo.ContactsFilter.SqlFilter);
      this.populateUpdateContactValuesFormArray(this._triggerToEdit.Parameters.UpdateContact, false);    
      this.populateUpdateContactValuesFormArray(this._triggerToEdit.Parameters.SecondListInfo.UpdateContact, true);  
      this.populateTime(this._triggerToEdit.Parameters.Scheduling.DateTime);
      this.populateMessageFolders();   
      this.setSelectedMessageFolderAndMessage();
    } else {
      this.populateListFolders();
      this.populateSecondListFolders();
      this.populateMessageFolders();
    }          
}

  populateLists(selectedListFolderId: number, selectedListId?: number) {

    this.listsService.getAllLightLists(selectedListFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._lightLists = this.lightListModelAdapter.adaptArray(executionResultDto.data);          
        this._lightListsDropdown = this._lightLists.map(x => ({
          value: x.ListId, 
          label: x.ListName
        }));

        this._listName = this._lightListsDropdown.find(x => x.value == selectedListId).label;
        this.stepForm.get('listForm').get('list').setValue(selectedListId);        
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });    
  }

  populateSecondLists(selectedListFolderId: number, selectedListId?: number) {

    this.listsService.getAllLightLists(selectedListFolderId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._secondListLightLists = this.lightListModelAdapter.adaptArray(executionResultDto.data);          
        this._secondListLightListsDropdown = this._secondListLightLists.map(x => ({
          value: x.ListId, 
          label: x.ListName
        }));

        this._secondListName = this._secondListLightListsDropdown.find(x => x.value == selectedListId).label;
        this.stepForm.get('listForm').get('secondList').setValue(selectedListId);        
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });    
  }
  
  populateListFolders(selectedFolderId?: number) { 
    this.listFolderService.getAll().subscribe(result => {
      this._listFolders = this.listFolderModelAdapter.adaptArray(result.data);      
       
      this._listFolderDropdown = this._listFolders.map(x => ({
        value: x.FolderID, 
        label: x.FolderName
      }));

      if (selectedFolderId) {
        this.stepForm.get('listForm').get('listFolder').setValue(selectedFolderId);
      }
       
    });
  } 

  populateSecondListFolders(selectedFolderId?: number) { 
    this.listFolderService.getAll().subscribe(result => {
      this._secondListListFolders = this.listFolderModelAdapter.adaptArray(result.data);      
       
      this._secondListListFolderDropdown = this._secondListListFolders.map(x => ({
        value: x.FolderID, 
        label: x.FolderName
      }));

      if (selectedFolderId) {
        this.stepForm.get('listForm').get('secondListFolder').setValue(selectedFolderId);
      }
         
    });
  } 

  populateListFields(selectedListId: number, selectedFieldIds?: number[]) { 

    this.listsService.getAllListFields(selectedListId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        
        this._fields = [];        
        this.fieldsArray.clear();

        this._fields = executionResultDto.data.map(data => ({
          id: data.FieldID, 
          name: data.DisplayName
        }));

        this._fields.forEach((field) => {

          let selected = selectedFieldIds && selectedFieldIds.some(x => x == field.id);        

          this.fieldsArray.push(new UntypedFormControl(selected))
          }
        );

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    }); 
  }  

  populateSecondListFields(selectedListId: number, selectedFieldIds?: number[]) { 

    this.listsService.getAllListFields(selectedListId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        
        this._secondListFields = [];        
        this.secondListFieldsArray.clear();

        this._secondListFields = executionResultDto.data.map(data => ({
          id: data.FieldID, 
          name: data.DisplayName
        }));

        this._fields.forEach((field) => {

          let selected = selectedFieldIds && selectedFieldIds.some(x => x == field.id);        

          this.secondListFieldsArray.push(new UntypedFormControl(selected))
          }
        );

      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    }); 
  } 

  populateMessages(selectedMessageFolderId, selectedMessageId?) {             
    this.messageService.getLightMessages(selectedMessageFolderId, this._currentSendMessageType).subscribe(result => {
      this._messages = this.lightweightMessageModelAdapter.adaptArray(result.data);
      this._notifyMessageDropdown = this._messages.map((message: LightweightMessageModel) => ({       
        value: message.MessageID,
        label: message.MessageName
      }));

      if (!this._triggerEdit)
        this.stepForm.get('messageForm').get('message').setValue(selectedMessageId);
      else
        this.stepForm.get('messageForm').get('message').setValue(this._triggerToEdit.Parameters.SendMessage.MessageId);
    });
  }

  populateMessageFolders(selectedMessageFolderId?: number) {   
    this._messageFoldersDropdown = this._messageFolders.map(x => ({
      value: x.id, 
      label: x.name
    })) 
    
    if (selectedMessageFolderId && !this._triggerEdit)
      this.stepForm.get('messageForm').get('messageFolder').setValue(selectedMessageFolderId);
  }  

  setSelectedMessageFolderAndMessage() {
    var message: LightweightMessageModel;
    var messageID = this._triggerToEdit.Parameters.SendMessage.MessageId;
    var messageType = this._triggerToEdit.Parameters.SendMessage.MessageType;

    this.triggersService.getLightMessage(messageID, messageType).subscribe(result => {
      message = this.lightweightMessageModelAdapter.adapt(result.data);
      this.stepForm.get('messageForm').get('messageFolder').setValue(message.FolderID);
      this.stepForm.get('messageForm').get('messageType').setValue(messageType);
      this.populateMessages(message.FolderID, messageID);
    });
  }


  populateTime(dateTime: Date) {    
    this.stepForm.get('messageForm').get('time').setValue(dateTime);
  }

  minSelectedCheckboxes(min: number = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls    
        .map(control => control.value)        
        .reduce((prev, next) => next ? prev + next : prev, 0);
  
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };
  
    return validator;
  }

  saveMultiListTrigger() {

    this._triggerToEdit.Title = this.triggerDetailsForm.get('title').value; 
    this._triggerToEdit.WorkflowId = this._workflowId;  
    
    const parameters = this._triggerToEdit.Parameters;
   
    parameters.TriggerTarget.ListId = this.stepForm.get('listForm').get('list').value;
    parameters.ContactsFilter.SqlFilter = this.stepForm.get('listForm').get('contactFilterCriteria').value;
    parameters.Scheduling.DateTime = this.stepForm.get('messageForm').get('time').value;
    parameters.Scheduling.DelayInSeconds = 0;
    parameters.UpdateContact = this.populateTriggerUpdateContactInfo(parameters.UpdateContact);

    parameters.SecondListInfo.ListId = this.stepForm.get('listForm').get('secondList').value;
    parameters.SecondListInfo.UpdateContact = this.populateTriggerUpdateContactInfo(parameters.SecondListInfo.UpdateContact, true);
    parameters.SecondListInfo.ContactsFilter.SqlFilter = this.stepForm.get('listForm').get('secondListContactFilterCriteria').value
  
    parameters.SendMessage = this.sendMessageActionInfoAdapter.createEmpty();

    parameters.SendMessage.MessageId = this.stepForm.get('messageForm').get('message').value;;
    parameters.SendMessage.MessageType = this.stepForm.get('messageForm').get('messageType').value;

    this._triggerToEdit.Parameters = parameters;    

    if (!this._triggerEdit) {
      this.triggersService.createTrigger(this._triggerToEdit).subscribe(result => {
        this.notificationService.showSuccess(result.message);
        this.router.navigate(['contact-plans/edit/', this._workflowId]);
      });
    } else {
      this.triggersService.editTrigger(this._triggerToEdit).subscribe(result => {
        this.notificationService.showSuccess(result.message);
        this.router.navigate(['contact-plans/edit/', this._workflowId]);
      });
    }
  }

  showMessagePreviewModal(messageId: number) {
    this.emailMessageService.getPreviewMessage(messageId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._messagePreviewHtml = executionResultDto.data.HtmlContent;
        this._messagePreviewModal.show();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }
  
  showSMSPreviewModal(messageId: number) {
    this.smsMessageService.get(messageId).subscribe((executionResultDto: ExecutionResultDto) => {
      if (executionResultDto.executionResult === ExecutionResult.success) {
        this._smsMessageContent = executionResultDto.data.SMSContent;
        this._smsMessageContentDate = executionResultDto.data.UpdatedDate;
        this._messagePreviewModal.show();
      } else {
        this.notificationService.showError(executionResultDto.message);
      }
    });
  }

  showSendContactsMessagePreviewModal() {
    this._messageTypeNum = this.stepForm.controls.messageForm.get('messageType').value;

    if (this._messageTypeNum == 1) this.showMessagePreviewModal(this._selectedMessageId);
    else this.showSMSPreviewModal(this._selectedMessageId);
  }
 

  showQueryBuilder() {
    if (!!this.stepForm.get('listForm').get('list').value) {
      this._isSecondList = false;
      this._selectedListId = this.stepForm.get('listForm').get('list').value
      this._selectedContactFilterCriteria = this.stepForm.get('listForm').get('contactFilterCriteria').value;
      this._queryBuilderParentSource = 'contacts';
      this._pageTitleForQueryBuilder = 'CreateEditMultiListTrigger';

      this.queryBuilder.populateQueryFilterList(this._pageTitleForQueryBuilder);
      this.initQueryBuilder(this._selectedListId, this._queryBuilderParentSource, this._selectedContactFilterCriteria);
      this.queryBuilderModal.show();
    }
  }

  secondListShowQueryBuilder() {
    if (!!this.stepForm.get('listForm').get('secondList').value) {
      this._isSecondList = true;
      this._selectedListId = this.stepForm.get('listForm').get('secondList').value
      this._selectedContactFilterCriteria = this.stepForm.get('listForm').get('secondListContactFilterCriteria').value;
      this._queryBuilderParentSource = 'contacts';
      this._pageTitleForQueryBuilder = 'CreateEditMultiListTrigger1';

      this.queryBuilder.populateQueryFilterList(this._pageTitleForQueryBuilder);
      this.initQueryBuilder(this._selectedListId, this._queryBuilderParentSource, this._selectedContactFilterCriteria);
      this.queryBuilderModal.show();
    }
  }

  showValidationError(forms: Array<string>) {
    switch (forms.length) {
      case 2:
        if (this.stepForm.get(forms[0]).get(forms[1]).invalid &&
          this.stepForm.get(forms[0]).get(forms[1]).touched) {
          return true;
        } else {
          return false;
        }
        break;
      case 3:
        if (this.stepForm.get(forms[0]).get(forms[1]).get(forms[2]).invalid &&
          this.stepForm.get(forms[0]).get(forms[1]).get(forms[2]).touched) {
          return true;
        } else {
          return false;
        }
        break;
    }
  }  

  populateUpdateContactValues() {

    this._updateContactValues = [];
    this.updateContactsComponent.fields.value.forEach(value => {
      const contactValue = this.changeModelAdapter.createEmpty();
      contactValue.Name = value.name;
      contactValue.UpdateType = value.updateType;

      const argument = value.argument ? value.argument : value.argumentFromEdit;

      switch (typeof argument) {
        case 'string':
          contactValue.Argument = argument;
          break;
        case 'number':
          contactValue.Argument = argument.toString();
          break;
        case 'object':
          if (argument) {
            if (typeof argument[0] === 'number') {
              contactValue.Argument = argument[3];
            } else {
              var argumentString = '';

              argument.forEach((arg, index) => {
                if (index > 0) {
                  argumentString = argumentString.concat(',');
                }
                argumentString = argumentString.concat(arg);
              });
              contactValue.Argument = argumentString;
            }
          }
          break;
      }
      this._updateContactValues.push(contactValue);
    });
  }

  showSelectedContactValues() {
    this._readableContactValues = [];

    const values = this.updateContactsComponent.fields.value;
    values.forEach(value => {
      const readableValue: Array<any> = [];
      if (value.displayName) {
        readableValue.push(value.displayName);
      }
      if (value.updateType) {
        switch (value.updateType) {
          case 'RawValue':
            readableValue.push('Set To');
            break;
          case 'FieldName':
            readableValue.push('Set To Field');
            break;
          case 'Reset':
            readableValue.push('Reset');
            break;
        }
      }

      let argument = value.argument ? value.argument : value.argumentFromEdit;
      const argumentDisplayValues = { 0: "No", 1: "Yes", };

      if (argument) {
        switch (typeof argument) {
          case 'string':
            argument = argumentDisplayValues[argument] || argument;
            readableValue.push(argument);
            break;
          case 'number':
            readableValue.push(argument);
            break;
          case 'object':
            if (typeof argument[0] === 'number') {
              readableValue.push(argument[3]);
            } else {
              argument.forEach(arg => {
                readableValue.push(arg);
              });
            }
        }
      }
      this._readableContactValues.push(readableValue);
    });
  }

  secondListPopulateUpdateContactValues() {

    this._secondListUpdateContactValues = [];
    this.secondListUpdateContactsComponent.secondListFields.value.forEach(value => {
      const contactValue = this.changeModelAdapter.createEmpty();
      contactValue.Name = value.secondListName;
      contactValue.UpdateType = value.secondListUpdateType;

      const argument = value.secondListArgument ? value.secondListArgument : value.argumentFromEdit;
     
      switch (typeof argument) {
        case 'string':
          contactValue.Argument = argument;
          break;
        case 'number':
          contactValue.Argument = argument.toString();
          break;
        case 'object':
          if (argument) {
            if (typeof argument[0] === 'number') {
              contactValue.Argument = argument[3];
            } else {
              var argumentString = '';

              argument.forEach((arg, index) => {
                if (index > 0) {
                  argumentString = argumentString.concat(',');
                }
                argumentString = argumentString.concat(arg);
              });
              contactValue.Argument = argumentString;
            }
          }
          break;
      }
      this._secondListUpdateContactValues.push(contactValue);
    });
  }

  secondLitShowSelectedContactValues() {
    this._secondListReadableContactValues = [];

    const values = this.secondListUpdateContactsComponent.secondListFields.value;
    values.forEach(value => {
      const readableValue: Array<any> = [];
      if (value.secondListDisplayName) {
        readableValue.push(value.secondListDisplayName);
      }
      if (value.secondListUpdateType) {
        switch (value.secondListUpdateType) {
          case 'RawValue':
            readableValue.push('Set To');
            break;
          case 'FieldName':
            readableValue.push('Set To Field');
            break;
          case 'Reset':
            readableValue.push('Reset');
            break;
        }
      }

      let secondListArgument = value.secondListArgument ? value.secondListArgument : value.argumentFromEdit;
      const argumentDisplayValues = { 0: "No", 1: "Yes", };

      if (secondListArgument) {
        switch (typeof secondListArgument) {
          case 'string':
            secondListArgument = argumentDisplayValues[secondListArgument] || secondListArgument;
            readableValue.push(secondListArgument);
            break;
          case 'number':
            readableValue.push(secondListArgument);
            break;
          case 'object':
            if (typeof secondListArgument[0] === 'number') {
              readableValue.push(secondListArgument[3]);
            } else {
              secondListArgument.forEach(arg => {
                readableValue.push(arg);
              });
            }
        }
      }
      this._secondListReadableContactValues.push(readableValue);
    });
  }

  initQueryBuilder(listID: number, source: string, filter: string) {
    this.queryBuilder._pageTitle = this._pageTitleForQueryBuilder;
    this.queryBuilder._sqlQuery = this._selectedContactFilterCriteria;

    this.queryBuilder.getListFields(listID, source);

  }

  showUpdateContactsModal() {
    this._isSecondListUpdateValues = false;
    this.updateContactValueModal.show();
  }

  secondListShowUpdateContactsModal() {
    this._isSecondListUpdateValues = true;    
    this.secondListUpdateContactValueModal.show();
  }

  populateTriggerUpdateContactInfo(updateContact: UpdateContactActionInfo, isSecondList = false): UpdateContactActionInfo {
    updateContact.Changes = isSecondList == false ? this._updateContactValues : this._secondListUpdateContactValues;
    return updateContact;
  }

  populateUpdateContactValuesFormArray(updateContacts: UpdateContactActionInfo, isSecondList: boolean) {

    if (!isSecondList) {
      updateContacts.Changes.forEach((change, index) => {
        this.updateContactsComponent.addField();
        this.updateContactsComponent.fields.controls[index].get('argumentFromEdit').setValue(change.Argument);
        this.updateContactsComponent.fields.controls[index].get('updateType').setValue(change.UpdateTypeName);
        this.updateContactsComponent.fields.controls[index].get('name').setValue(change.Name);
      });

      //this.showSelectedContactValues();
      this.populateUpdateContactValues();
    } else {
      updateContacts.Changes.forEach((change, index) => {
        this.secondListUpdateContactsComponent.addField();
        this.secondListUpdateContactsComponent.secondListFields.controls[index].get('argumentFromEdit').setValue(change.Argument);
        this.secondListUpdateContactsComponent.secondListFields.controls[index].get('secondListUpdateType').setValue(change.UpdateTypeName);
        this.secondListUpdateContactsComponent.secondListFields.controls[index].get('secondListName').setValue(change.Name);
      });

      //this.secondLitShowSelectedContactValues();
      this.secondListPopulateUpdateContactValues();
    }
  }

  removeSameListFromSecondListDropDown() {
    if (this._selectedFolderId == this._secondListSelectedFolderId) {
      const index = this._secondListLightListsDropdown.findIndex(x => x.value == this._selectedListId);
      if (index) {
        this._secondListLightListsDropdown.splice(index, 1);
      }      
    }
  }

}
