import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MessageGridRecordDto } from '../../../_models/messaging/messages/message-grid-record-dto';
import { MessageTypeEnum } from '../../../_models/messaging/messages/message-type-enum';
import { MatSort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { TableService } from '../../../_services/tables/table.service';
import { LocalStorageService } from '../../../_services/system/localStorage/localStorage.service';
import { UnsubscribeService } from '../../../_services/system/unsubscribe.service';
import { PermissionsPageTypes } from '../../../_models/system/permissions/permissions-page-types-model';
import { PermissionsService } from '../../../_services/system/Permissions/permissions.service';
import {
  ContactPlanGridRecordDto
} from '../../../_models/messaging/automation/contact-plans/contact-plan-grid-record-dto';
import { MatDialog } from '@angular/material/dialog';
import { TableActionName, TableActionType, TableName, TableType } from '../constants/table-constants';
import { FilterModalComponent } from '../modals/filter/filter-modal.component';
import { ITableColumns } from '../../../_models/tables/table-interfaces';
import { MessagingHeaderService } from '../../../_services/messaging/messaging-header/messaging-header.service';
import { SelectListOption } from 'src/app/_models/system/select-list-option';
import { PaginationDataService } from '../../../_services/tables/pagination-data/pagination-data.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() tableKey: string;
  @Input() displayedColumns: ITableColumns[];
  @Input() showActionSection = true;
  @Input() showTitleSection = true;
  @Input() data: any[] = [];
  @Input() length: number;
  @Input() selectedFolderId: number;
  @Input() selectedListId: number;
  @Input() isSwitchOn: boolean;
  @Input() isSearchOn: boolean;
  @Input() actionList: string[];
  @Input() sortColumn = 'ChangeDate';
  @Input() permission: PermissionsPageTypes;
  @Input() filterMessageRules: string;
  @Input() ownerPage: string;

  @Output() actionEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableData: EventEmitter<any> = new EventEmitter<any>();
  @Output() switchPlan: EventEmitter<ContactPlanGridRecordDto> = new EventEmitter<ContactPlanGridRecordDto>();

  liveDelFormGroup: UntypedFormGroup;
  optionList = ['move', 'copy', 'searchReplace', 'clear'];
  hideColumns: ITableColumns[];
  columnsToDisplay: string[];
  tableType: number;
  tableTypes = TableType;
  tableName: string;
  actionNames = TableActionName;
  actionTypes = TableActionType;
  showLiveMessages = true;
  selectedRows = [];
  subscriptionTableData: Subscription;
  subscriptionShowListMessages: Subscription;
  selection = new SelectionModel<MessageGridRecordDto>(true, []);
  previewDisabled = true;
  copyMoveDisabled = true;
  sendDisabled = true;
  selectedFilter: number;
  sortDirection = 'Descending';
  pageSizeOptions: number[] = [10, 25, 50, 100];
  currentPage = 1;
  pageLimit = 10;
  pageIndex = 0;
  isCopy = false;
  currentClientId: number;
  messageTypeEnumSms = MessageTypeEnum.sms;
  tableHasRows = false;
  userPermissionsSMS: PermissionsPageTypes;
  isExportEnable: boolean;
  uploadListName: string;
  pages: SelectListOption[] = [];
  notParentTable: boolean;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private tableService: TableService,
    private localStorageService: LocalStorageService,
    private permissionsService: PermissionsService,
    private messagingHeaderService: MessagingHeaderService,
    private matDialog: MatDialog,
    private paginationDataService: PaginationDataService,
    private childPaginationDataService: PaginationDataService,
  ) { }

  ngOnInit(): void {
    this.currentClientId = +this.localStorageService.getCurrentClientId;
    this.tableName = TableName[this.tableKey];
    this.tableType = TableType[this.tableKey];
    this.isExportEnable = this.tableType === TableType.emailReports
      || this.tableType === TableType.smsReports
      || this.tableType === TableType.contacts;

    if (TableType[this.tableKey] === TableType.messages) {
      this.userPermissionsSMS = this.permissionsService.getSmsPermission();
    }

    this.initForms();

    this.subscriptionTableData = this.tableService.getTableData().subscribe((notParent: boolean) => {

      this.notParentTable = notParent;
      this.selection.clear();
      let isFilterValue = false;
      this.resetPaginator(notParent, isFilterValue);
      this.getDataForTable();
    });
  }

  ngAfterViewInit(): void {
    this.subscribeToFormControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tableHasRows = !!this.data?.length;
    if (changes.displayedColumns && this.displayedColumns?.length) {
      this.displayedColumns = this.displayedColumns.map((column) => {
        column.checked = true;
        return column;
      });

      this.hideColumns = this.displayedColumns
        .map(column => Object.assign({}, column))
        .filter((col) => col.displayName);

      this.fillColumnsToDisplay();
    }
  }

  dataLoaded() {    
    if(this.tableHasRows && this.length) {
      this.pages.splice(0);
      var totalPages = Math.ceil(this.length / this.pageLimit);

      for (let i = 0; i < totalPages; i++) {
        var page = new  SelectListOption(i + 1, (i + 1).toString());    
        this.pages.push(page);
      }
    } 
  }

  fillColumnsToDisplay(): void {
    this.columnsToDisplay = [];
    this.columnsToDisplay = this.displayedColumns
      .filter((column) => column.checked)
      .map((col) => col.name);
  }

  toggle(data): void {
    this.displayedColumns.find((column) => column.name === data.source.name).checked = data.checked;
    this.fillColumnsToDisplay();
  }

  editPermissionValidator(row): boolean {
    return row.messageTypeEnum === this.messageTypeEnumSms ? this.userPermissionsSMS.Edit : this.permission?.Edit;
  }

  deletePermissionValidator(row): boolean {
    return row.messageTypeEnum === this.messageTypeEnumSms ? this.userPermissionsSMS.Delete : this.permission?.Delete;
  }

  resetPaginator(notParent: boolean, isFilterValue: boolean): void {
    this.currentPage = 1;
    this.pageLimit = 10;
    this.sortColumn = notParent
      ? 'ContactId'
      : TableType.contacts !== TableType[this.tableKey]
        ? 'ChangeDate'
        : 'UpdatedDate';
    this.sortDirection = 'Descending';
    this.pageIndex = 0;

    if (!isFilterValue) {
      this.filterMessageRules = '';
    }
  }

  addFields(data) {
    data.isLive = !this.showLiveMessages;
    data.filters = this.filterMessageRules;

    return data;
  }

  getDataForTable(): void {
    this.selection.clear();
    this.activateSettings();

    let requestOptions;
    this.childPaginationDataService.GetChildPaginationDataFromLocalStorage();
    this.paginationDataService.GetPaginationDataFromLocalStorage();


    if (this.paginationDataService.paginationData.lastPage?.toUpperCase() === this.tableName?.toUpperCase()
      && this.paginationDataService.paginationData.page) {
      requestOptions = {
        currentPage : this.paginationDataService.paginationData.page,
        pageLimit : this.paginationDataService.paginationData.pageLimit,
        sortColumn : this.paginationDataService.paginationData.sortByColumn,
        sortDirection: this.paginationDataService.paginationData.sortByDirection,
        isLive: !this.showLiveMessages,
        filters: this.filterMessageRules,
      }
    }
    else if (this.childPaginationDataService.childPaginationData.lastPage?.toUpperCase() === this.tableName?.toUpperCase()
      && this.childPaginationDataService.childPaginationData.page)
    {
      requestOptions = {
        currentPage: this.childPaginationDataService.childPaginationData.page,
        pageLimit: this.childPaginationDataService.childPaginationData.pageLimit,
        sortColumn: this.childPaginationDataService.childPaginationData.sortByColumn,
        sortDirection: this.childPaginationDataService.childPaginationData.sortByDirection,
        isLive: !this.showLiveMessages,
        filters: this.filterMessageRules,
      }
    }
    else {
       requestOptions = {
        currentPage: this.currentPage,
        pageLimit: this.pageLimit,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        isLive: !this.showLiveMessages,
        filters: this.filterMessageRules,
      };
    }

    this.pageIndex = requestOptions.currentPage - 1;
    this.currentPage = requestOptions.currentPage;
    this.pageLimit = requestOptions.pageLimit;

  
    if (this.tableType === TableType.email) {
      this.tableData.emit(requestOptions);
    } else {
      requestOptions = this.addFields(requestOptions);
      this.tableData.emit(requestOptions);
    }     
  }

  initForms(): void {
    this.liveDelFormGroup = new UntypedFormGroup({
      showLiveMessages: new UntypedFormControl('checked'),
      goToPageForm: new UntypedFormControl(0)
    });    
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.data.forEach(row => this.selection.select(row));
  }

  activateSettings(row?: any): void {
    if (this.selection.selected.length === 1) {
      this.previewDisabled = row.messageTypeEnum !== MessageTypeEnum.email;
      this.sendDisabled = false;
      this.copyMoveDisabled = false;
    } else if (this.selection.selected.length > 1) {
      this.previewDisabled = true;
      this.sendDisabled = true;
      this.copyMoveDisabled = false;
    } else if (!this.selection.selected.length) {
      this.previewDisabled = true;
      this.sendDisabled = true;
      this.copyMoveDisabled = true;
    }
  }

  checkAllMessages(): void {
    this.masterToggle();
    this.selectedRows = this.data;
    this.activateSettings();
  }

  checkMessage(row: any): void {
    this.selection.toggle(row);
    this.selectedRows = this.selection.selected;
    this.activateSettings(row);
  }

  subscribeToFormControls(): void {
    this.subscriptionShowListMessages = this.liveDelFormGroup.get('showLiveMessages').valueChanges
      .subscribe((checked: boolean) => {
        this.selection.clear();
        this.showLiveMessages = checked;
        this.selectedRows = [];
        this.getDataForTable();
      });
  }

  goToPage(page: any) {
      var pageEvent = new PageEvent();
      pageEvent.length = this.length;
      pageEvent.pageSize = this.pageLimit;
    pageEvent.pageIndex = page.value - 1;

    this.onChangePage(pageEvent);
  }

  openFilter(): void {
    const dialog = this.matDialog.open(FilterModalComponent, {
      width: '800px',
      data: {
        selectedFilter: this.filterMessageRules,
        selectedId: TableType.contacts === TableType[this.tableKey] ? this.selectedListId : this.selectedFolderId,
        tableKey: this.tableKey,
      }
    });

    dialog.afterClosed().subscribe(({ isNeedToUpdate, filter, rules }) => {
      if (isNeedToUpdate) {
        this.filterMessageRules = rules;
        let isFilterValue = true;
        this.resetPaginator(false, isFilterValue);
        this.getDataForTable();
      }
    });
  }

  onSortColumn(): void {
    if (this.sort.direction === '') {
      this.sortColumn = this.notParentTable
        ? 'ContactId'
        : TableType.contacts !== TableType[this.tableKey]
          ? 'ChangeDate'
          : 'UpdatedDate';
    } else if (this.sort.direction === 'desc') {
      this.sortDirection = 'Descending';
      this.sortColumn = this.sort.active;
    } else {
      this.sortDirection = 'Ascending';
      this.sortColumn = this.sort.active;
    }

    this.getDataForTable();
  }

  checkboxLabel(row?: any): string {
    if (row) {
      return `${ this.selection.isSelected(row) ? 'deselect' : 'select' } row ${ row.position + 1 }`;
    } else {
      return `${ this.isAllSelected() ? 'select' : 'deselect' } all`;
    }
  }

  onChangePage(event: PageEvent): void  {  
    this.selection.clear();
    this.length = event.length;
    this.pageLimit = event.pageSize;
    this.currentPage = +event.pageIndex + 1;
    this.pageIndex = +event.pageIndex;

    if (this.tableName.toUpperCase() != TableName.email.toUpperCase() && this.tableName.toUpperCase() != 'Message History') {
      this.paginationDataService.SetPaginationDataToLocalStorage(
        this.currentPage, this.pageLimit, this.sortColumn, this.sortDirection, this.tableName);
    }
    else {
      this.childPaginationDataService.SetChildPaginationDataToLocalStorage(
        this.currentPage, this.pageLimit, this.sortColumn, this.sortDirection, this.tableName);
    }

    this.getDataForTable();
  }

  tableAction(actionKey: string): void {
    this.actionEmit.emit({ selectedRows: this.selectedRows,  action: actionKey });
  }

  disableOption(action: string): boolean {
    if (this.optionList.includes(action)) {
      return this.copyMoveDisabled;
    } else if (action === 'preview') {
      return this.previewDisabled;
    } else if (action === 'send') {
      return this.sendDisabled;
    } else if (TableActionType[action] === TableActionType.uploadNewList) {
      return !((this.permission?.Create || this.permission?.Edit) && this.selectedFolderId);
    } else if (TableActionType[action] === TableActionType.uploadList) {
      this.defineUploadListName(action);

      return !((this.permission?.Create || this.permission?.Edit) &&
        (TableType.lists === TableType[this.tableKey] ? this.selectedRows?.length === 1 : this.selectedListId));
    } else if (TableActionType[action] === TableActionType.uploadHistory) {
      return !this.permission?.View;
    }
  }

  defineUploadListName(action: string): void {
    if (TableType.lists === TableType[this.tableKey]) {
      this.uploadListName = this.selectedRows.length === 1 ? this.selectedRows[0].ListName : '';
    } else {
      this.uploadListName = this.messagingHeaderService.lists?.find((list) => list.value === this.selectedListId)?.label || '';
    }
  }

  switchContactPlan(contactPlan: ContactPlanGridRecordDto): void {
    this.actionEmit.emit({ selectedRows: contactPlan, action: 'switchStatus'});
    contactPlan.status = !contactPlan.status;
  }

  deleteRestore(row): void{
    this.actionEmit.emit(
      {
        selectedRows: row,
        action: 'delete',
        extraOptions: {
          isLiveMessage: this.showLiveMessages,
          tableKey: this.tableKey
        }
      }
    );
  }

  edit(row): void {
    this.actionEmit.emit({ selectedRows: row, action: 'edit'});
  }

  trialOpen(row): void {
    this.actionEmit.emit({ selectedRows: row, action: 'trial'});
  }

  editForms(row): void {
    this.actionEmit.emit({ selectedRows: row, action: 'editForms'});
  }

  listContacts(row): void {
    this.actionEmit.emit({ selectedRows: row, action: 'listContacts'});
  }

  openDetails(row): void {
    this.actionEmit.emit({ selectedRows: row, action: 'openDetails' });
  }

  export(): void {
    this.actionEmit.emit({ selectedRows: this.displayedColumns.map((column) => column.displayName), action: 'export' });
  }

  getMessageHistory(row): void {
    this.actionEmit.emit({ selectedRows: row, action: 'getMessageHistory' });
  }

  ngOnDestroy(): void {
    UnsubscribeService.unsubscribe(this.subscriptionShowListMessages, this.subscriptionTableData);
  }
}
