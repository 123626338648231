import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { MessageTypeEnum } from 'src/app/_models/messaging/messages/message-type-enum';
import { PagerDtoRequest } from 'src/app/_models/messaging/pager-dto-request';
import { MessageCopyMoveDto } from 'src/app/_models/messaging/messages/message-copy-move-dto';
import { MessageSearchReplaceDto } from 'src/app/_models/messaging/messages/message-search-replace.dto';
import { MessageHistoryDtoRequest } from 'src/app/_models/messaging/messages/message-history-dto-request';
import { ContactMessageHistoryDtoRequest } from 'src/app/_models/messaging/messages/contact-message-history-dto-request';

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  private _baseUrl = environment.apiURL;
  private _controllerName = 'Message';
  private _options = { headers: new HttpHeaders() };

  constructor(private http: HttpClient,
              private executionResultAdapter: ExecutionResultAdapter) { }

  delete(id: number): Observable<ExecutionResultDto> {

    return this.http
    .patch(`${this._baseUrl}${this._controllerName}/Delete/${id}`, this._options)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getMessages(pagerDtoRequest: PagerDtoRequest, folderId: number, isDeleted: boolean, filter: string): Observable<ExecutionResultDto> {

    const query = `?Page=${pagerDtoRequest.page}` +
                   `&PageSize=${pagerDtoRequest.pageSize}` +
                   `&SortColumn=${pagerDtoRequest.sortColumn}` +
                   `&SortDirection=${pagerDtoRequest.sortDirection}` +
                   `&folderId=${folderId}` +
                   `&isDeleted=${isDeleted}` +
                   (filter ? `&filter=${encodeURIComponent(filter)}` : '');

    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetMessages${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  getMessageHistory(messageHistoryDtoRequest: MessageHistoryDtoRequest): Observable<ExecutionResultDto> {
    return this.http.post(`${this._baseUrl}${this._controllerName}/GetMessageHistory`, messageHistoryDtoRequest)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  async getContactMessageHistory(contactMessageHistoryDtoRequest: ContactMessageHistoryDtoRequest): Promise<ExecutionResultDto> {
    return await this.http.post(`${this._baseUrl}${this._controllerName}/GetContactMessageHistoryByContactId`, contactMessageHistoryDtoRequest).toPromise()
      .then(result => this.executionResultAdapter.adapt(result));
  }

  getLightMessages(folderID: number, messageType: MessageTypeEnum): Observable<ExecutionResultDto> {
    
    const query = `?FolderID=${folderID}` +
                   `&messageType=${messageType}`;

    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetLightMessages${query}`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  restore(id: number): Observable<ExecutionResultDto> {

    return this.http
    .patch(`${this._baseUrl}${this._controllerName}/Restore/${id}`, this._options)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  checkMessageName(moveCopyDto: MessageCopyMoveDto): Observable<ExecutionResultDto> {
    return this.http.post(`${this._baseUrl}${this._controllerName}/checkMessageNames`, moveCopyDto)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  copyMoveMessage(moveCopyDto: MessageCopyMoveDto): Observable<ExecutionResultDto> {
    return this.http.post(`${this._baseUrl}${this._controllerName}/CopyMoveMessage`, moveCopyDto)
      .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  searchReplaceMessage(messageSearchReplaceDto: MessageSearchReplaceDto): Observable<ExecutionResultDto> {
    return this.http.post(`${this._baseUrl}${this._controllerName}/MessageSearchReplace`, messageSearchReplaceDto)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  // get the fields that will be used in the querybuilder
  getMessageFields() { 
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/GetMessageFields`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  UseUnlayer(): Observable<ExecutionResultDto> {     
    return this.http
    .get(`${this._baseUrl}${this._controllerName}/UseUnlayer`)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
}
}

