import { MatDialog } from '@angular/material/dialog';
import { TableService } from '../../_services/tables/table.service';
import { Injector } from '@angular/core';
import { DeleteRestoreModalComponent } from '../../views/shared/modals/delete-restore/delete-restore-modal.component';
import { Router } from '@angular/router';
import { PermissionsService } from '../../_services/system/Permissions/permissions.service';
import { PaginationDataService } from '../../_services/tables/pagination-data/pagination-data.service';
import { TableName, TableType } from '../../views/shared/constants/table-constants';
import { PermissionsMessagingPages } from '../system/permissions/permissions-messaging-pages.model';
import { ITableColumns, TableTypesUnion } from '../tables/table-interfaces';
import { SelectListOption } from '../system/select-list-option';
import { NotificationService } from '../../_services/notification.service';
import { FolderDtoAdapter } from './messages/folder-dto.adapter';
import { LoadingSpinnerService } from '../../_services/loading-spinner/loading-spinner.service';
import { MessagingHeaderService } from '../../_services/messaging/messaging-header/messaging-header.service';
import { ExecutionResultDto } from '../execution-result-model';

export class MessagingAbstract {
  displayedColumns: ITableColumns[];
  matDialog: MatDialog;
  tableService: TableService;
  router: Router;
  permissionsService: PermissionsService;
  paginationDataService: PaginationDataService;
  notificationService: NotificationService;
  folderDtoAdapter: FolderDtoAdapter;
  loadingSpinnerService: LoadingSpinnerService;
  messagingHeaderService: MessagingHeaderService;
  data: any[];
  permissions: PermissionsMessagingPages;
  executionResultDto: ExecutionResultDto;
  selectedFolderId: number;
  tableKey: string;
  tableName: string;
  length: number;


  // variables for the table
  currentPage = 1;
  pageCount = 0;
  pageLimit = 10;
  pageOffset = 0;

  //Default column sorting value variables
  sortColumn: string = 'UpdatedDate';
  sortDirection: string = 'Descending';

  constructor(injector: Injector) {
    this.permissionsService = injector.get(PermissionsService);
    this.matDialog = injector.get(MatDialog);
    this.tableService = injector.get(TableService);
    this.router = injector.get(Router);
    this.paginationDataService = injector.get(PaginationDataService);
    this.notificationService = injector.get(NotificationService);
    this.folderDtoAdapter = injector.get(FolderDtoAdapter);
    this.loadingSpinnerService = injector.get(LoadingSpinnerService);
    this.messagingHeaderService = injector.get(MessagingHeaderService);
  }

  init() {
    this.tableName = TableName[this.tableKey];
    if (this.paginationDataService.getPreviousPage() !== TableType[this.tableKey]) {
      this.selectedFolderId = 0;
      this.paginationDataService.resetLocalStorage('FolderId');
      this.paginationDataService.resetLocalStorage('ListId');
      this.paginationDataService.resetFilterRules();
    }

    this.paginationDataService.setPreviousPage(TableType[this.tableKey]);
    this.permissions = this.permissionsService.getPermissionsModel().Messaging;
  }

  openModal(modal, config) {
    const dialog = this.matDialog.open(modal, config);

    dialog.afterClosed().subscribe(({ isNeedToUpdate }) => {
      if (isNeedToUpdate) {
        this.tableService.requestTableData();
      }
    });
  }

  deleteRestoreRow(row: TableTypesUnion, options): void {
    const data = { row, options };
    this.openModal(DeleteRestoreModalComponent, { data });
  }

  editRow(row, path: string): void {
    this.router.navigate([ path, row.messageId || row.messageCodeId || row.contactPlanId]);
  }

  folderSelected(id: number): void {

    if (this.selectedFolderId != undefined && this.selectedFolderId != 0 && this.selectedFolderId != id) {
        this.paginationDataService.SetPaginationDataToLocalStorage
          (this.currentPage, this.pageLimit, this.sortColumn, this.sortDirection, this.tableName);
    }

    this.selectedFolderId = id;
    this.paginationDataService.SetFolderIdToLocalStorage(this.selectedFolderId);
    this.tableService.requestTableData();
  }

  copyMove({ selectedRows, modal, dataService, isCopy, tableKey }) {
    const folders = this.messagingHeaderService.folders.filter((folder) => {
      return (folder.value !== 0);
    });

    const data = {
      copyMoveList: JSON.parse(JSON.stringify(selectedRows)),
      dataService,
      isCopy,
      folders,
      tableKey
    };

    this.openModal(modal, { data });
  }
}
