import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { ExecutionResult } from 'src/app/_models/execution-result-enum';
import { ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { QueryBuilderFilterDtoRequest } from 'src/app/_models/query-builder-filters/query-builder-filter-dto-request';
import { NotificationService } from 'src/app/_services/notification.service';
import { QueryBuilderFilterService } from 'src/app/_services/query-builder-filters/query-builder-filter.service';
import { QueryBuilderHelper } from '../query-builder-helper';

@Component({
  selector: 'app-query-builder-filter',
  templateUrl: './query-builder-filter.component.html',
  styleUrls: ['./query-builder-filter.component.scss']
})
export class QueryBuilderFilterComponent implements OnInit, AfterViewInit {

  //Variables used for Saving to the DB
  _pageTitle: string = '';
  _sqlQuery: string = '';

  //Model View
  @ViewChild('saveFilterModal', { static: true }) saveFilterModal: ModalDirective;

  //Inputs and outputs for components
  @Input() _parentPageTitle: string = '';
  @Input() _parentSqlQuery: any;
  @Output() modelOpenEvent = new EventEmitter<boolean>();
  @Output() closeRefreshEvent = new EventEmitter<boolean>();

  //Form Control and Validation
  _newFilterForm = new UntypedFormGroup({
    filterName: new UntypedFormControl('', Validators.required),
  });

  constructor(private queryBuilderFilterService: QueryBuilderFilterService,
              private notificationService: NotificationService,
              private queryBuilderHelper: QueryBuilderHelper) { }

  ngOnInit() {
    //Check if the data from the querybuilder is not empty
    if (this._parentSqlQuery.sql != '' && this._parentPageTitle != '') {
      this._sqlQuery = this._parentSqlQuery.sql;
      this._pageTitle = this._parentPageTitle;
    }
  }

  ngAfterViewInit() {
    this.saveFilterModal.show();
  }

  //Validate the users filter name input, Page Title and SQL Query, and save filter.
  saveFilterSubmit() {
    const filterName = this._newFilterForm.get('filterName').value;

    if (filterName != '' && this._sqlQuery != '' && this._pageTitle != '') {

      //Remove ISNULL - This is not required to be saved in db
      var modifiedSQLQuery = this.queryBuilderHelper.removeIsNullFromSQL(this._sqlQuery);

      //Add Parameters to DTO Request
      const queryBuilderFilterDtoRequest = new QueryBuilderFilterDtoRequest({
        pageTitle: this._pageTitle,
        filterName: filterName,
        sqlQuery: modifiedSQLQuery
      });

      //Call the API to Save the SQL and Reset the Query Builder and Filter
      this.queryBuilderFilterService.addFilter(queryBuilderFilterDtoRequest).subscribe((executionResultDto: ExecutionResultDto) => {
        if (executionResultDto.executionResult === ExecutionResult.success) {
          this.closeRefreshEvent.emit(true);
          this.saveFilterModal.hide();
          this.notificationService.showSuccess(executionResultDto.message);
        } else {
          this.notificationService.showError(executionResultDto.message);
        }
      });
    }
  }
}