export class EditStepRequestDto {

    id: number;
    contactPlanId: number;
    title: string;
    status: boolean;

    constructor(init: {id: number, contactPlanId?: number, status?: boolean, title?: string}) {
        this.id = init.id;
        this.contactPlanId = init.contactPlanId;
        this.status = init.status;
        this.title = init.title;
    }
}
