import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExecutionResultAdapter, ExecutionResultDto } from 'src/app/_models/execution-result-model';
import { map } from 'rxjs/operators';
import { QueryBuilderFilterDtoRequest } from 'src/app/_models/query-builder-filters/query-builder-filter-dto-request';

@Injectable({
    providedIn: 'root'
  })
export class QueryBuilderFilterService {
  public selectedFilter = 0;
  public appliedFilter: string;
  private _baseUrl = environment.apiURL;

  constructor(
    private http: HttpClient,
    private executionResultAdapter: ExecutionResultAdapter
  ) {  }

  getFiltersByPageTitle(pageTitle: string) {
    return this.http
    .get(this._baseUrl + 'QueryBuilder/GetSavedFilters?pageTitle=' + pageTitle)
    .pipe(map((data: any) => this.executionResultAdapter.adapt(data)));
  }

  addFilter(queryBuilderFilterDto: QueryBuilderFilterDtoRequest) {
    return this.http.post(this._baseUrl + 'QueryBuilder/AddFilter', queryBuilderFilterDto).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }

  updateFilter(queryBuilderFilterDto: QueryBuilderFilterDtoRequest) {
    return this.http.post(this._baseUrl + 'QueryBuilder/UpdateFilter', queryBuilderFilterDto).pipe(map((data: any) =>
    this.executionResultAdapter.adapt(data)));
  }
}
